@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
  font-family: "Raleway";
}

.login-main-section {
  display: flex;
  justify-content: center;
  flex: 1;
}

.phoneInput {
  width: 350px;
}

.selfpace {
  height: 90%;
  margin-left: -8.5cm;
  opacity: 1;
}

.pace {
  margin-top: 150px;
  color: yellow;
  font-weight: 700;
  font-size: 70px;
  margin-left: 590px;
}

.div1st {
  font-size: large;
  color: grey;
}

.welcome-slider {
  height: 100vh;
  width: 50vw;
}

.welcome-slider-img {
  height: 100vh;
  width: 50vw;
}

.login-imgsection img {
  width: 960px;
  height: 1054px;
}

.login-heading {
  display: flex;
  justify-content: flex-start;
  font-size: 40px;
  font-weight: 700;
}

.Signup-signuphead {
  padding-left: 20%;
}

.signup-loginhead {
  opacity: 0.2;
}

.bye {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.Person-text1 {
  border: unset;
  border-bottom: 1px solid #a0a0a0;
  font-size: 14px;
}

.Person-text1:focus {
  outline: none;
}

/* ============ Learn More Button ======  */
.button {
  display: inline-block;
  padding: 0.75rem 2.25rem;
  border-radius: 10rem;
  color: black;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
}

.button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d3f433;
  border-radius: 10rem;
  z-index: -2;
}

.button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button:hover {
  color: rgb(0, 0, 0);
}

.button:hover:before {
  width: 100%;
}

.signup-termpolicy {
  padding-top: 5%;
}

.login-footer {
  display: block;
  justify-content: center;
  text-align: center;
}

.login-socialmedia {
  display: flex;

  height: 35px;
  margin-top: 3%;
  margin-bottom: 5%;
}

.login-socialmedia div img {
  height: 35px;
}

/* ############ Signup page #################### */

.button {
  display: inline-block;
  /* padding: 0.75rem 1.25rem; */
  border-radius: 10rem;
  color: black;
  font-size: 14px;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
}

.login-heading {
  display: flex;
  justify-content: flex-start;
  font-size: 40px;
  font-weight: 700;
}

.signup-termpolicy {
  font-size: 12px;
}

.login-logo-white {
  width: 45%;
}

.slider-cnt-heading {
  color: white;
  font-size: 70px;
}

.slider-cnt-Subheading {
  color: white;
  font-size: 16px;
}

.login-wrapp .carousel-indicators {
  bottom: 55px !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100% !important;
  justify-content: center !important;
}

.person-text-signup {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.term-policy-signup {
  margin-left: 5px;
}

.login-wrapp .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 40px;
  height: 8px;
}

/* ============== Left Side Slider =============  */
.item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.owl-dot span {
  width: 26px;

  color: blue;
  background-color: white;
  border-bottom: 5px solid var(--bs-blue);
  display: inline-block;
  margin: 5px;
}

.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item,
.item,
.item img{
  height: 100vh;
  object-fit: cover;
}

.slider-cnt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.slider-cnt-heading {
  color: white;
  font-size: 50px;
}

.slider-cnt-Subheading {
  color: white;
  font-size: 20px;
}

.carousel-indicators li {
  background-color: var(--theme-blue) !important;
  border: 2px solid var(--theme-blue);
}

.carousel-indicators {
  right: auto !important;
  left: 15% !important;
  margin-right: auto !important;
  margin-left: 0 !important;
}

.carousel-indicators li {
  width: 21px;
  height: 1px;
}

.owl-carousel.my-first-carousel.owl-loaded.owl-drag {
  position: relative;
}

.owl-dots {
  position: absolute;
  top: 87%;
  left: 42%;
}

/* =============== Verify Your Email =================  */
.verify-emailcontainer {
  text-align: center;
}

.verify-heading {
  font-size: 30px;
  font-weight: 700;
}

.verify-line {
  width: 30%;
  border: 2px solid #565c83;
  opacity: 1;
  margin-left: 34%;
}

/* ================== Register ===================  */
.register-contentpage.container {
  max-width: 50% !important;
}

.register-contentpage {
  text-align: left;
}

.register-content {
  margin-bottom: 20px;
}

.register-heading {
  font-size: 35px;
  font-weight: 700;
}

.register-line {
  width: 42%;
  border: 2px solid #565c83;
  opacity: 1;
}

.register-smile {
  width: 60px;
  height: 65px;
}

.btn-v:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d3f433;
  border-radius: 10rem;
  z-index: -2;
}

.btn-v:hover {
  color: black;
}

.btn-v:hover:before {
  width: 100%;
}

/* =============== forget password ===========  */
.forgetpassword-heading {
  font-size: 35px;
  font-weight: 700;
}

.forgetpassword-line {
  width: 45%;
  border: 2px solid #565c83;
  opacity: 1;
}

.forgetpassword-emailsection {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 30vw;
  margin-bottom: 8%;
  margin-top: 8%;
}

/* ================ password reset ============= */

.passwordreset-text {
  display: flex;
}

.abc {
  margin-left: 20px;
}

.tickicon {
  width: 150px;
}

.resend-password {
  margin-top: 20px;
}

/* ######################## React CSS #######################  */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Saira+Semi+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

body {
  font-family: "Raleway";
  font-size: 14px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-raleway);
}

:root {
  --theme-blue: #3855f4;

  --theme-parrot: #d3f433;
  --theme-black: black;
  --theme-white: white;
  --font-raleway: "Raleway", sans-serif;
}

.height {
  height: 100%;
}

.homepage-lightnavbar-heading {
  margin-top: 10%;
}

.light-cart {
  margin-left: 15px;
}

.subnavbar-light {
  color: black;
  font-weight: 600;
  margin-right: 15px;
}

.navbar-expand .navbar-nav .nav-link :hover {
  font-weight: 600;
}

@media (max-width: 1400px) {
  .navbar-nav {
    margin-left: 10%;
  }
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  margin-left: 15px;
}

.i-page-header.container {
  max-width: 100% !important;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar-nav li {
  margin: 0px 10px;
}

.mainlogo {
  padding-left: 40px;
}

.nav-set {
  padding: 30px 45px 0px 45px;
  position: absolute;
  top: 0;
  transition: top 0.3s;
}

.navbar-nav {
  align-items: center;
}

.inner-navbar a {
  color: var(--theme-black) !important;
  font-weight: 600;
}

.inner-navbar::before {
  height: 0%;
  width: 90%;
  bottom: 0px;
}

.inner-navbar::before {
  height: 3px;
}

.fix {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.container {
  flex: 1;
}

.bttnshop4 {
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
  padding: 2px 2px;
  background-color: var(--theme-parrot);
  color: var(--theme-white);
  border-radius: 30px;
  transition: all 0.3s ease;
  color: black;
  border: 2px solid var(--theme-parrot);
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 129px;
  height: 42px;
}

/* .bttnshop4:hover {
    border: 2px solid #CFE9CC;
    border-radius: 18px;
    background-color: #dbf074;
    color: var(--theme-black);
} */

.middlebttn {
  margin: 0px;
  font-size: 18px;
}

.cart-icon {
  width: 28px;
  margin-right: 20px;
}

.carousel-button button {
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
}

.home-carosule-item {
  height: 100vh;
}

.slder-text-size {
  font-size: 45px !important;
  font-weight: 200;
}

.carousel-indicators li {
  background-color: var(--theme-blue) !important;
  border: 2px solid var(--theme-blue);
}

.slder-text-h-size {
  font-size: 87px;
  width: 300px;
  font-weight: bold;
  color: var(--theme-parrot);
  margin: unset !important;
}

.homepage-headingline {
  width: 150px;
}

.homepage-hoverline {
  width: 100px;
  border: 1px solid #000000;
  opacity: 1;
}

.slder-text-p-size {
  font-size: 28px;
  font-weight: 200;
  width: 25%;
  float: left;
}

.carousel-item {
  height: 100vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-caption {
  bottom: 270px;
}

.carousel-caption h5 {
  font-size: 45px;
  letter-spacing: 2px;
  margin-top: 25px;
  text-align: left;
}

.carousel-caption p {
  margin: auto;
  font-size: 18px;
  line-height: 1.9;
  text-align: left;
}

.navbar-light .navbar-brand {
  color: var(--theme-white);
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: var(--theme-white);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--theme-white);
}

.navbar-toggler {
  background: var(--theme-white);
}

.nav-effect:hover {
  color: #d3f433 !important;
}

.navbar-nav {
  text-align: center;
}

.nav-link {
  padding: 0.2rem 1rem;
  position: relative;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: var(--theme-blue);
}

.nav-link.active,
.nav-link:focus {
  color: var(--theme-white);
}

.navbar-toggler {
  padding: 1px 5px;
  font-size: 18px;
  line-height: 0.3;
}

.homepage-lightnavbar-heading:hover {
  color: #d3f433;
  transition: 300ms;
}

.hover-blue:hover {
  border-bottom: 4px solid var(--theme-blue);
  transition: 300ms;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--theme-white);
  color: #d3f433;
}

.nav-link-grow-up::before {
  height: 0%;
  width: 100%;
  bottom: 0px;
  color: #d3f433;
  left: 0;
}

.nav-link-grow-up:hover::before {
  height: 3px;
  color: #d3f433;
}

.slider-div-botm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-div-box {
  display: flex;
  align-items: center;
  background-color: var(--theme-white);
  margin: -5%;
  box-shadow: 0px 6px 45px #00000029;
  z-index: 14;
  border-radius: 15px;
  padding: 25px;
}

.test-size-slider-down {
  font-size: 30px;
  font-weight: 300;
}

.test-size-slider-down2 {
  font-size: 48px;
  font-weight: bold;
  color: var(--theme-blue);
  line-height: 2rem;
}

.learnmore-button {
  padding: 0;
  border: none;
  background: none;
  color: var(--theme-blue);
  font-size: 18px;
  font-weight: 600;
}

.test-size-slider-down-pera {
  font-size: 18px;
  font-weight: 400;
  max-width: 55rem;
  margin-top: 2%;
}

.vertical-boxline {
  border-left: 1px solid #707070;
  height: 40px;
  margin: 0px 13px 0px 13px;
}

.what-uni-div {
  margin: 0px 0px 0px 15px;
}

.section2-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section2-heading h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.first-slider .slick-slider .slick-track,
.first-slider .slick-slider .slick-list {
  padding-top: 0 !important;
  padding-bottom: 0;
}

.section2-heading-our-clnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  text-align: center;
}

.section2-heading-our-clnt h1 {
  margin-top: 60px;
  font-size: 43px;
  font-weight: 600;
}

.section-content {
  max-width: 60%;
  font-size: 18px;
  font-weight: 400;
}

.line {
  margin: 20px 0;
  width: 7%;
  text-align: center;
  border-radius: 15px;
  border-top: 3px solid var(--theme-black);
}

.card {
  width: 450px;
  height: 450px !important;
  margin: 0 20px;
  border-radius: 20px;
  overflow: hidden;
  border: 8px solid var(--theme-white);
  position: relative;
  background-color: var(--theme-white);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-img {
  width: auto;
  object-fit: cover;
}

.card-body {
  width: 100%;
  top: 0;
  top: 100%;
  position: absolute;
  background: var(--theme-parrot);
  backdrop-filter: blur(5px);
  color: var(--theme-black);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s;
}

.card:hover .card-body {
  top: 0;
}

.card-title {
  font-size: 50px;
  font-weight: 500;
}

.card-sub-title {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  font-weight: 700;
}

.card-info {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.card-btn {
  color: var(--theme-black);
  background: var(--theme-parrot);
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: capitalize;
  outline: none;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}

.card:hover .card-body {
  top: 0;
  height: 100%;
}

.card-btn {
  border: 1px solid black;
  margin-left: 20px;
}

.first-card-style {
  margin: 12px 0px 0px 5px;
  font-size: 25px;
  font-weight: 600;
  padding-left: 20px;
}

.first-pera-style {
  font-size: 15px;
  font-weight: 400;
  margin: 12px 0px 0px 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.card-hover-down {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-hover-down-inset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
}

.card-hover-down-font {
  font-size: 10px;
  margin-bottom: unset;
}

.card-hover-downin-font {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}

.vertical {
  border-left: 1px solid #707070;
  height: 80px;
  margin: 0px 25px 0px 25px;
}

.arrow-box {
  display: flex;
  width: 47px;
  height: 47px;
  border-radius: 8px;
  margin: 12px;
  background-color: var(--theme-white);
  box-shadow: rgba(65, 63, 63, 0.182) 0px 3px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slider-div {
  border-radius: 22px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.staars {
  color: var(--theme-blue);
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 10px;
}

.staars div {
  margin: 0px 2px;
}

.slider-wps {
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.wps-name-postion {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wps-pera {
  font-size: 18px;
  color: #000;
}

.our-client {
  background-color: #f5f5f5;
}

.our-clnt-slider {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

.our-clnt-slider div img {
  width: 100%;
  height: 70%;
  margin-bottom: 66px;
  padding-right: 2rem;
}

.learnmoretext {
  color: #3855f4;
}

.learnmoretext :hover {
  text-decoration: none;
}

.date-color {
  margin: 15px 0px;
  color: #636466;
  font-size: 12px;
}

.trending-text {
  font-size: 17px;
  font-weight: 700;
}

.trending-text1 {
  font-size: 14px;
  align-items: center;
  display: flex;
}

.trending-text1 img {
  margin: 0px 5px;
}

.trnd-heading-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.trnd-heading-last h2 {
  font-size: 45px;
  font-weight: 600;
}

/* slider wps  */

.button,
.button {
  position: relative;
  display: table;
  padding: 0.75em 3em;
  color: #fff;
  text-decoration: none;
  margin-top: 1.5em;
}

.wrapper,
.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
}

.swiper {
  padding: 3em 0em 0em 0em;
}

.swiper .content-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ".";
  width: 100%;
  justify-items: center;
  align-items: center;
}

.swiper .content-wrapper .content {
  padding: 3em 1.5em;
  text-align: center;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  color: #000;
}

.swiper .content-wrapper .content :first-child {
  margin: 0;
}

.swiper .content-wrapper .content .swiper-avatar {
  width: 100%;
  max-width: 125px;
  height: auto;
}

.swiper .content-wrapper .content .swiper-avatar img {
  border-radius: 500px;
}

.swiper .content-wrapper .content .cite {
  font-size: 14px;
  font-weight: bold;
}

.swiper .swiper-slide {
  height: auto;
  width: 550.333px !important;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}

.swiper .swiper-slide.swiper-slide-active {
  background: rgb(255, 255, 255);
  opacity: 1;
  transform: scale(1.1);
  width: 550.333px !important;
}

.swiper .swiper-nav-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: auto;
  padding-top: 3em;
}

.swiper .swiper-nav-wrapper .swiper-button-next,
.swiper .swiper-nav-wrapper .swiper-button-prev {
  top: 0;
  top: auto;
  left: auto;
  right: auto;
  position: relative !important;
}

.swiper .swiper-nav-wrapper .swiper-button-next:after,
.swiper .swiper-nav-wrapper .swiper-button-prev:after {
  display: none;
}

.swiper .swiper-nav-wrapper .swiper-button-next,
.swiper .swiper-nav-wrapper .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.swiper .swiper-nav-wrapper .swiper-button-prev,
.swiper .swiper-nav-wrapper .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' transform='translate(4.508789, 7.870605) rotate(-180.000000) translate(-4.508789, -7.870605) translate(-0.000000, -0.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.swiper-pagination {
  margin: 0;
  padding: 0;
  width: auto;
  position: relative !important;
  display: block;
  width: auto;
}

.swiper-pagination .swiper-pagination-bullets {
  margin: 0;
}

.swiper-pagination .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
  background: #fff !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  transition: all 0.2s ease-in-out;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  bottom: 0;
  width: auto;
  padding: 0 1.5em;
}

.swiper-pagination-bullet-active {
  transform: scale(1.5);
}

/* ======= slider Aniation Trending Now ======  */

.div-size-set-last>div>img {
  width: 100%;
  object-fit: cover;
}

.div-size-set-last>div>img {
  width: 100%;
  height: 209px;
  object-fit: cover;
}

.card-div {
  background-color: #ffffff;

  position: relative;
  border: 2px solid #ffffff;
}

.card-div:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.last-slider-wid-set {
  padding: 12px;
}

.footer-background {
  background-color: #475dd5;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 4rem 0rem;
}

.bottom-footer {
  display: flex;
  justify-content: space-between;
  background: #4156cb 0% 0% no-repeat padding-box;
  color: white;
  font-size: 15px;
  padding: 1rem 0;
  letter-spacing: 3px;
}

.footer-div-set {
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin: 1em 0em;
  align-content: space-around;
}
.footer-icon-set {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-row {
  margin-left: 7%;
}

.footer-icon-set p {
  margin: unset;
}

.footer-icon-set-btn {
  width: 40px;
  color: #000;
  border-radius: 50%;
}

.footer-icon-set-btn :hover {
  border-radius: 9px;
  color: rgba(34, 32, 32, 0.823);
}

.footer-links {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.followus-footer {
  font-size: 18px;
  font-weight: 400;
}

.footer-links1 {
  font-size: 12px;
  margin-top: 8px;
  opacity: 0.7;
  color: white;
  text-decoration: none;
}

.footer-a {
  color: white;
  text-decoration: none;
}

.footer-a:hover {
  color: white;
  text-decoration: underline;
}

.side {
  margin-top: 33%;
}

.phone-text {
  font-size: 21px;
  font-style: normal !important;
  text-decoration: none;
  color: white;
  font-family: "Poppins";
  margin-left: 16px;
  --bs-link-hover-color: none;
}

/* ============== testimonial slider ==========  */

.wrapper-slider-new {
  padding: 0px 0px;
}

/* Default slide */
.slick-slide {
  background-color: transparent !important;
}

.center-slider .slick-slide {

  transform: scale(0.8);

  transition: all 0.4s ease-in-out;
}

.center-slider .slick-slide,
.center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
  transform: scale(0.8, 0.8);

  transition: all 0.4s ease-in-out;
}

/* Active center slide (You can change anything here for cenetr slide)*/

.center-slider .slick-center,
.center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
  transform: scale(1.1);
}

.center-slider .slick-current.slick-active {
  transform: scale(1.1);
  transform: scale(1.2);
    background-color: #ffffff !important;
}

.slick-next,
.slick-prev {
  z-index: 5;
}

.slick-next {
  right: 15px;
}

.slick-prev {
  left: 15px;
}

.slick-next:before,
.slick-prev:before {
  color: #000;

  font-size: 26px;
}

.slider-wps {
  position: relative;
  border-radius: 15px;
  position: relative;
}

.staars {
  color: blue;
  display: flex;
  justify-content: center;
}

.wps-icon {
  position: absolute;

  top: -50px;

  left: 38%;
}

.para-text {
  margin-left: 20px;

  margin-right: 20px;

  text-align: center;
}

.wps-name-postion {
  display: flex;

  justify-content: center;

  align-items: center;
}

.wps-pera {
  font-size: 18px;

  color: #000;
}

.wps-name {
  color: #000;

  font-size: 22px;
margin: 0;
  margin-right: 5px;

  font-weight: 600;
}

.wps-postion {
  font-size: 18px;
  margin: 0;

  color: #7a7a7a;
}

.writeus-hero-img {
  width: 100%;
  height: 415px;
}

.whatsapp-text {
  font-size: 14px;
  font-weight: bold;
}

.message-hr {
  width: 112px;
  border: 1px solid #565c83;
}

.message-whatsapp-text {
  margin-left: 5%;
}

.message-whatsapp-icon {
  width: 41px;
  height: 41px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  padding-top: 45px !important;
  padding-bottom: 50px;
}

.botton-image {
  width: 90px;
  height: 100px;
  margin-top: 20%;
}

.bottom-heading {
  font-size: 26px;
  color: black;
  font-weight: 800;
}

/* Write to us css */

.message-heading {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;
}

.message-text {
  font-size: 16px;
  margin-top: 10px;
}

.whatsapp-text {
  font-size: 14px;
  font-weight: bold;
}

.message-hr {
  width: 112px;
  border: 1px solid #565c83;
}

.message-whatsapp {
  display: flex;
  justify-content: flex-start;
  margin-top: 17%;
}

.message-emailus {
  margin-top: 7%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: unset;
}

.whatsapp-bottom-text {
  margin-bottom: unset;
}

.contact-bottom-margin-unset {
  margin-bottom: unset;
}

.message-whatsapp-text {
  margin-left: 5%;
}

.message-whatsapp-icon {
  width: 41px;
  height: 41px;
}

.Person-text1 {
  border: unset;
  border-bottom: 1px solid #767676;
  font-size: 14px;
}

.Person-text1:focus {
  outline: none;
}

.bye {
  display: flex;
  flex-direction: column;
  position: relative;
}

.writetous-submit-button {
  display: inline-block;
  padding: 0.75rem 2.25rem;
  border-radius: 10rem;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  border: none;
}

.writetous-submit-button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d3f433;
  border-radius: 10rem;
  z-index: -2;
}

.writetous-submit-button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.writetous-submit-button:hover {
  color: black;
}

.-button:hover:before {
  width: 100%;
}

.message-sent-img {
  text-align: center;
}

/* Your questions css */

.question-category {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 !important;
}

.category-itmes {
  border-bottom: 2px solid #a0a0a0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

#hidden-arrow {
  display: none;
}


.hiddsen-arrow {
  display: none;
}


.category-itmes:hover #hidden-arrow {
  display: block;
}

.inp-box {
  border: none;
}

.inp-box:focus {
  border: none;
  outline: none;
}

.inp-button {
  border: none;
  width: 24px;
  height: 24px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  background-color: white;
}

.line-break {
  width: 200px;
}

.accordion {
  width: 100%;
}

.accordion .accordion-item {
  border-bottom: 2px solid #e5e5e5;
}

.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #3855f4;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  font-weight: 800;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #3855f4;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #3855f4;
  border: 1px solid #3855f4;
}

.accordion button .accordion-title {
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  color: #565c83;
  pointer-events: none;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] {
  color: #3855f4;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}

.accordion button[aria-expanded="true"]+.accordion-content {
  opacity: 1;
  max-height: 40em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 600;
  margin: 2em 0;
}

.bottom-box {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #d3f433;
  padding: 4%;
  border-radius: 15px;
  position: relative;
}

.botton-image {
  width: 100px;
  height: 100px;
  margin-top: 20%;
  opacity: 0.7;
}

.bottom-heading {
  font-size: 30px;
  color: black;
  font-weight: 700;
}

.bottom-text {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: normal;
}

.your-button {
  text-decoration: none;
  font-size: 12px;
  padding: 2px 2px;
  background-color: blue;
  color: var(--theme-white);
  border-radius: 30px;
  transition: all 0.3s ease;
  color: white;
  border: 2px solid var(--theme-parrot);
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 129px;
  height: 42px;
  margin-top: 10px;
  --bs-link-hover-color: white;
}

.header-img-set {
  position: relative;
  text-align: center;
  display: flex;
}

.left-block {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.left-img-set {
  width: 100%;
  height: 100%;
}

.right-img-set {
  width: 255px;
  display: none;
}

.video-thumbnail {
  width: 200px;
  /* Change this value to the desired width */
  height: 200px;
  /* Change this value to the desired height */
  object-fit: cover;
  /* This will make the image fill the container without distortion */
}

.right-top-block {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.main-img {
  width: 75%;
  margin-left: auto;
}

.main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-img-set {
  max-height: 394px;
}

/* ==================Responsive====================== */
@media (min-width: 1681px) {
  .header-img-set {
    max-height: 574px;
  }
}

/* What's unique css */

.hero-img {
  max-width: 100%;
}

.second-section-img {
  opacity: 1;
  position: relative;
}

img {
  max-width: 100%;
}

.gap-y {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.gap-top {
  margin-top: 100px !important;
}

.gap-top-sm {
  margin-top: 50px !important;
}

.gap-bottom {
  margin-bottom: 100px !important;
}

.gap-bottom-sm {
  margin-bottom: 50px !important;
}

.second-section-text {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  margin-top: 5rem;
}

/* =========== Third Section =========  */

.third-section-text {
  max-width: 100vw;
  text-align: left;
  font-size: 24px;
  font-weight: 300 !important;
  margin-top: 5rem;
  margin-right: 10rem;
}

.third-section1 {
  display: flex;
  justify-content: space-around;
  padding: 10%;
  margin-top: -15%;
}

.vision-card {
  display: flex;
  justify-content: space-evenly;
}

.vision-card-text {
  box-shadow: 0px 3px 56px #00000029;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  padding: 2%;
  width: 47%;
}

.vision-card-text h2 {
  margin-left: 24px;
  font-size: 34px;
  font-weight: 500;
}

.vision-card-text p {
  margin: 24px;
}

.slider-unique {
  text-align: center;
}

.our-team-card {
  display: flex;
  text-align: center;
  position: relative;
}

.link-img {
  position: absolute;
  left: 50%;
  top: 126px;
  transform: translateX(-50%);
  z-index: 1;
}

.prof-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.our-team-card p {
  width: 100%;
  margin-top: 35px;
  margin-left: 0;
}

.explore-sharebutton {
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
  align-items: center;
  font-size: 14px;
  list-style: none;
}

.explore-shareline {
  margin-top: 2%;
}

.explore-sharebutton-icon {
  padding: 2%;
}

.explore-left-img {
  max-width: 100%;
}

.explore-right-small {
  display: flex;
}

.explore-heading {
  font-size: 25px;

  font-weight: 600;

  margin: unset;
}

.explore-heading-right {
  font-size: 25px;

  font-weight: 600;

  margin-top: 0%;
}

.explore-date {
  font-size: 14px;

  color: #7b7b7b;

  margin-bottom: 3%;
}

.explore-bottomtext {
  font-size: 16px;

  max-width: 100%;

  margin-top: 3%;
}

.explore-righttext {
  font-size: 20px;

  font-weight: 600;

  margin: unset;
  color: black;
  text-decoration: none;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 103%;
    display: block;
}

.explore-righttext:hover {
  color: black;
  text-decoration: underline;
}

.explore-rightline {
  width: 95%;

  margin-top: 5%;

  margin-bottom: 5%;
}

.explore-right-img {
  margin-right: 20px;
  width: 100%;
  height: 80px;
}

.explore-footer {
  display: flex;

  justify-content: flex-start;

  list-style: none;

  font-size: 14px;
}

.explore-footer-icons {
  width: 34.83px;

  height: 32.83px;
}

.explore-footer li {
  padding: 5%;
}

/* How can we help css */

.helpsection-main {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  align-items: flex-start;
}

.helpsection-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.helpsection-img {
  width: 30%;
}

.helpsection-content {
  width: 70%;
  margin-left: 50px;
}

.helpsection-heading {
  font-size: 30px;
  font-weight: 700;
}

.helpsection-context {
  font-size: 18px;
}

/* ============ Learn More Button ======  */

.button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d3f433;
  border-radius: 10rem;
  z-index: -2;
}

.button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button:hover {
  color: black;
}

.button:hover:before {
  width: 100%;
}

/* ============== Login ==============  */
.login-main-section {
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100vh;
}

.login-contentpage {
  width: 85%;
}

.welcome-slider {
  height: 100vh;
  width: 50vw;
}

.welcome-slider-img {
  height: 100vh;
  width: 50vw;
}

.login-imgsection img {
  width: 960px;
  height: 1054px;
}

.login-signuphead {
  translate: 20%;
}

.text-light-dim {
  opacity: 0.2;
}

.bdr-btom {
  border-bottom: 4px solid #565c83;
}

.person {
  position: absolute;
  right: 0;
}

.person-text {
  font-size: 14px;
  font-weight: 600;
}

.Person-text1 {
  border: unset;
  border-bottom: 1px solid #a0a0a0;
  font-size: 14px;
}

.Person-text1:focus {
  outline: none;
}

/* ============ Learn More Button ======  */
.howcanwehelp-button {
  display: inline-block;
  background-color: var(--theme-parrot);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  border: 3px solid var(--theme-parrot);
}

button {
  display: inline-block;
  padding: 0.75rem 2.25rem;
  border-radius: 10rem;
  color: black;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  margin-left: 6%;
}
.howcanwehelp-button:hover {
  color: black;
  border: 3px solid var(--theme-parrot);
  background-color: var(--theme-parrot);
  transition: 300ms;
}

.login-rememberme {
  font-size: 13px;
}

.login-account {
  font-size: 13px;
}

.login-socialmedia {
  display: flex;
  justify-content: space-evenly;
  height: 50px;
  margin-bottom: 40px;
  margin-top: 15px;

  align-items: center;
}

.login-socialmedia div img {
  height: 35px;
}

/* ============== Left Side Slider =============  */
.item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.owl-dot span {
  width: 26px;
  color: blue;
  background-color: white;
  border-bottom: 5px solid var(--bs-blue);
  display: inline-block;
  margin: 5px;
}

.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item,
.item,
.item img {
  height: 100vh;
  object-fit: cover;
}

.slider-cnt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.carousel-indicators li {
  background-color: var(--theme-blue) !important;
  border: 2px solid var(--theme-blue);
}

.carousel-indicators li {
  width: 21px;
  height: 1px;
}

.owl-carousel.my-first-carousel.owl-loaded.owl-drag {
  position: relative;
}

.owl-dots {
  position: absolute;
  top: 87%;
  left: 42%;
}

.card {
  width: 91% !important;
  border-radius: 20px;
  overflow: hidden;
  border: 8px solid var(--theme-white);
  position: relative;
  background-color: var(--theme-white);
}

/* ==================Responsive====================== */
@media (min-width: 1681px) {
  .header-img-set {
    max-height: 574px;
  }

  body {
    font-size: 16px;
  }
}

/* ............ laptop responsive ............  */

@media only screen and (max-width: 1396px) and (min-width: 992px) {
  .navbar-brand img {
    width: 160px;
  }

  .navbar-nav {
    font-size: 12px;
    margin-top: 0.5%;
  }

  .test-size-slider-down-pera {
    font-size: 15px;
    font-weight: 400;
    width: inherit;
    max-width: 41rem;
    margin-top: 2%;
  }

  .slider-div-box {
    width: 75vw;
    display: flex;
    align-items: center;
    background-color: var(--theme-white);
    box-shadow: 0px 6px 45px #00000029;
    z-index: 14;
    border-radius: 15px;
    padding: 25px;
  }

  .carousel-button button {
    border-radius: 50%;
    height: 15px !important;
    width: 15px !important;
  }

  .slder-text-p-size {
    font-size: 28px;
    font-weight: 200;
    width: 31%;
    float: left;
  }

  .navbar-nav {
    font-size: 12px;
    margin-top: 0.5%;
  }

  .slider-div-box {
    width: 75vw;
    display: flex;
    align-items: center;
    background-color: var(--theme-white);
    margin: -90px;
    box-shadow: 0px 6px 45px #00000029;
    z-index: 14;
    border-radius: 15px;
    padding: 25px;
  }

  .test-size-slider-down2 {
    font-size: 35px;
    font-weight: bold;
    color: var(--theme-blue);
    line-height: 2rem;
  }

  .test-size-slider-down {
    font-size: 25px;
    font-weight: 300;
  }

  .test-size-slider-down-pera {
    font-size: 15px;
    font-weight: 400;
    max-width: 46rem;
    margin-top: 2%;
  }

  .vertical {
    border-left: 1px solid #707070;
    height: 65px;
    margin: 0px 25px 0px 25px;
    opacity: 0.5;
  }

  .card-btn {
    color: var(--theme-black);
    background: var(--theme-parrot);
    padding: 7px;
    border-radius: 5px;
    text-transform: capitalize;
    outline: none;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
    font-size: 12px;
  }

  .card-img {
    height: 120%;
    object-fit: cover;
  }

  .learnmore-button {
    font-size: 12px;
    margin-top: 1%;
  }

  .section-content {
    max-width: 44%;
    font-size: 14px;
    font-weight: 400;
  }

  .card-sub-title {
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0px;
    font-weight: 700;
  }

  .card-info {
    font-size: 13px;
    line-height: 25px;
    margin: 0px 0;
    font-weight: 400;
  }

  .arrow-box {
    display: flex;
    width: 47px;
    height: 47px;
    border-radius: 8px;
    background-color: var(--theme-white);
    box-shadow: rgb(65 63 63 / 18%) 0px 3px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .wps-name-postion {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3%;
  }

  .section2-heading h1 {
    font-size: 34px;
    font-weight: 700;
  }

  .trnd-heading-last h2 {
    font-size: 40px;
    font-weight: 600;
  }

  .phone-text {
    font-size: 16px;
    text-decoration: none;
    color: white;
    font-family: "Poppins";
    margin-left: 10px;
  }

  .footer-links {
    font-size: 16px;
    font-weight: 400;
  }

  .footer-links1 {
    font-size: 11px;
    margin-top: 8px;
    opacity: 0.9;
  }

  .side {
    margin-top: 43px;
  }

  .followus-footer {
    font-size: 16px;
    font-weight: 400;
    padding-left: 14px;
  }

  .bottom-footer {
    background: #4156cb 0% 0% no-repeat padding-box;
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
  }

  .footer-socialmedia-icon {
    width: 27px;
  }

  .helpsection-main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .helpsection-heading {
    font-size: 26px;
    font-weight: 700;
  }

  .helpsection-context {
    font-size: 14px;
  }

  .yourquestion-main-heading {
    position: absolute;
    font-weight: 800;
    top: 20%;
    z-index: 99;
    font-size: 2em;
    left: 17%;
  }

  .message-text {
    font-size: 13px;
    margin-top: 10px;
  }

  .message-whatsapp-icon {
    width: 32px;
    height: 36px;
  }

  .write-tous {
    position: absolute;
    font-weight: 800;
    top: 28%;
    z-index: 99;
    font-size: 2em;
    left: 20%;
  }

  .writetous-submit-button {
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    color: black;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;
  }

  .Person-text1 {
    border: unset;
    border-bottom: 1px solid #a0a0a0;
    font-size: 12px;
  }

  .explore-left-img {
    max-width: 100%;
  }

  .explore-bottomtext {
    font-size: 13px;
    margin-top: 3%;
    width: 120%;
  }

  .explore-bottomtext {
    margin-top: 3%;
  }

  .explore-heading {
    font-size: 22px;
    font-weight: 800;
    margin: unset;
  }

  .explore-date {
    font-size: 12px;
    color: #7b7b7b;
    font-weight: 300;
    margin-bottom: 3%;
  }

  .explore-heading-right {
    font-size: 22px;
    font-weight: 800;
    margin-top: 0%;
  }

  .explore-righttext {
    font-size: 17px;
    font-weight: 700;
    margin: unset;
  }

  .explore-sharebutton {
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    align-items: center;
    font-size: 14px;
    list-style: none;
  }

  .explore-footer-icons {
    width: 30.83px;
    height: 30.83px;
  }

  .footer-logo-img {
    width: 41%;
  }

  .phone-icon {
    width: 25px;
  }

  .footer-div-set {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin: 1em 0em;
    margin-right: 10%;
    align-content: space-around;
  }

  .vision-card-text {
    width: 46%;
    height: 250px;
    box-shadow: 0px 3px 56px #00000029;
    border-radius: 10px;
    text-align: left;
    padding: 2%;
  }

  .vision-card-text p {
    margin: 24px;
    font-size: 14px;
  }

  .vision-card-text h2 {
    margin-left: 24px;
    font-size: 28px;
    font-weight: 800;
  }

  .team-head {
    font-size: 46px;
    font-weight: 600;
  }

  .botton-image {
    width: 100px;
    height: 100px;
    margin-top: 20%;
    opacity: 1;
  }

  .bottom-box {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: rgb(211, 244, 51);
    position: relative;
    right: 11%;
    padding: 3%;
    border-radius: 15px;
  }

  .bottom-heading {
    font-size: 28px;
    color: black;
    font-weight: 700;
  }

  .bottom-text {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: normal;
  }

  .first-card-style {
    margin: 50px 0px 0px 5px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 9px;
  }

  .first-pera-style {
    font-size: 13px;
    font-weight: 400;
    margin: 12px 0px 0px 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .middlebttn {
    margin: 0px;

    font-size: 14px;
  }

  .bttnshop4 {
    width: 96px;

    height: 34px;
  }

  .cart-icon {
    width: 23px;
  }

  .carousel-caption p {
    margin: auto;

    font-size: 16px;

    line-height: 1.7;
  }

  .carousel-indicators li {
    width: 21px;

    height: 1px;
  }

  .carousel-caption p {
    font-size: 16px;
    line-height: 1.7;
  }

  .slder-text-size {
    font-size: 30px !important;
    font-weight: 200;
  }

  .carousel-caption h5 {
    font-size: 36px;
    letter-spacing: 2px;
    margin-top: 25px;
  }

  .wps-pera {
    font-size: 12px;
    color: #000;
  }

  .wps-name {
    font-size: 14px;
  }

  .wps-postion {
    font-size: 14px;

    margin: 0;

    color: #7a7a7a;
  }

  .wps-icon {
    position: absolute;
    top: -50px;
    left: 35%;
  }

  .center-slider .slick-slide {
    color: black;
    transform: scale(0.8);
    transition: all 0.4s ease-in-out;
  }

  .our-clnt-slider img {
    width: 140px;
    margin-bottom: 100px;
  }
}

/* ===========================30 December, 2022=============================== */
.carousel-caption {
  width: 100% !important;
  position: absolute;
    left: 15% !important;
    right: auto !important;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-item-cnt {
  display: flex;
  align-items: center;
  height: 100%;
}

.slick-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

textarea {
  border: 0 !important;
  border-bottom: 1px solid #a0a0a0 !important;
  min-height: 20px;
  outline: 0 !important;
}

.first-slider .slick-slide {
  margin: 0;
}

p:nth-last-child(1) {
  margin-bottom: 0;
}

.first-slider {
  display: flex;
  justify-content: center;
  width: 75%;
}

.wrapper-slider-new {
  height: 60vh;
}

@media (min-width: 992px) {
  .container {
    max-width: 714px !important;
  }
}

@media (min-width: 768px) {

  .col-20 {
    width: 20% !important;
  }
  .btn-v.sm{
    padding: 10px 6px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1026px !important;
  }
}

@media (max-width: 991px) {
  .navbar-nav {
    display: none !important;
  }

  .slider-div-box {
    flex-wrap: wrap;
  }

  .vertical {
    display: none;
  }

  .slider-div-box {
    width: 80vw;
    align-items: center;
    background-color: var(--theme-white);
    margin: -5%;
    box-shadow: 0px 6px 45px #00000029;
    z-index: 14;
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    justify-content: center;
  }

  .card {
    height: 365px !important;
  }

  .first-card-style {
    margin: 30px 0px 0px 0px;
    font-size: 21px;
    font-weight: 600;
    padding-left: 15px;
  }

  .mainlogo {
    padding-left: 10px;
    height: 55px;
  }

  .second-section-text {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: 30px !important;
  }

  .vision-card-text {
    box-shadow: 0px 3px 56px #00000029;
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    padding: 2%;
    width: 49%;
  }

  .bottom-footer {
    color: white;
    font-size: 10px;
  }

  .footer-icon-set {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .followus-footer {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  .tr-wrap {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tr-wrap .col-lg-4 {
    max-width: 46%;
  }

  .tr-wrap::-webkit-scrollbar {
    display: none;
  }

  .phone-text {
    font-size: 15px;
    font-style: normal !important;
    text-decoration: none;
    color: white;
    font-family: "Poppins";
    margin-left: 8px;
    --bs-link-hover-color: none;
  }

  .footer-callicon {
    width: 23px;
  }

  .footer-main-logo {
    height: 40px;
  }

  .footer-links {
    color: white;
    font-size: 16px;
    font-weight: 400;
  }

  .footer-links1 {
    font-size: 10px;
    margin-top: 8px;
    opacity: 0.7;
    color: white;
    text-decoration: none;
  }

  .side {
    margin-top: 43%;
  }

  .footer-icon-set-btn {
    margin: 3px;
  }

  .center-slider .slick-current.slick-active>div {
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .slick {
    transform: scale(1.1) !important;
  }

  .p.footer-icon-set img {
    width: 50px;
  }

  .left-img-set {
    display: none;
  }

  .main-img {
    width: 100%;
  }

  .left-block {
    width: 55%;
    height: auto;
    padding: 25px;
    border-radius: 10px;
    bottom: -16px;
    background-color: #d3f433;
    box-shadow: 0px 6px 45px #00000029;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-info {
    font-size: 10px;
    line-height: 25px;
    font-weight: 400;
  }

  .card-sub-title {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    font-weight: 700;
  }

  .card-btn {
    color: var(--theme-black);
    background: var(--theme-parrot);
    padding: 5px;
    border-radius: 5px;
    text-transform: capitalize;
    outline: none;
    font-weight: 500;
    cursor: pointer;
  }

  .card:hover .card-body {
    top: 0;
    height: 100%;
  }

  .category-itmes {
    border-bottom: 2px solid #a0a0a0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
  }

  .example {
    display: none;
  }

  .bottom-box {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #d3f433;
    padding: 4%;
    border-radius: 15px;
    position: relative;
  }

  .accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    font-size: 15px;
    font-weight: 800;
    border: none;
    background: none;
    outline: none;
  }

  .question-accordion {
    display: block;
  }

  .explore-right-img {
    margin-right: auto;
    width: 100%;
    height: 100%;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* ================== mobile Responsive =============  */
@media (max-width: 767px) {

  nav .container,
  nav .container-sm {
    max-width: 100% !important;
  }

  .overlay-text {
    margin-top: 20% !important;
  }

  .order-mb-1 {
    order: 1 !important;
  }

  .order-mb-2 {
    order: 2 !important;
  }

  .vision-card-text {
    box-shadow: 0px 3px 56px #00000029;
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    padding: 2%;
    width: 100%;
  }

  .vision-card .container div :nth-child(2) {
    margin-top: 30px;
  }

  .pagination-container .pagination-item {
    margin-top: 0 !important;
  }

  .second-section-img {
    width: 70%;
  }

  .side {
    margin-top: 8px;
  }

  .bottom-footer {
    color: white;
    font-size: 10px;
    flex-wrap: wrap;
    text-align: center;
  }

  .howcanwehelp-top-left-double-headig {
    font-size: 18px !important;
    left: 15% !important;
    position: absolute !important;
    font-weight: 800 !important;
    top: 20% !important;
    z-index: 99 !important;

  }

  .left-block {
    width: 55%;
    height: auto;
    border-radius: 10px;
    bottom: -16px;
    background-color: #d3f433;
    box-shadow: 0px 6px 45px #00000029;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .helpsection-heading {
    font-size: 18px;
    font-weight: 700;
  }

  .helpsection-context {
    font-size: 12px;
  }

  .howcanwehelp-button {
    font-size: 10px;
  }

  .message-heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .message-text {
    font-size: 11px;
    margin-top: 10px;
  }

  .explore-right-img {
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  .wps-postion,
  .wps-pera {
    font-size: 10px;
  }

  .wps-name {
    font-size: 8px;
  }

  .wps-icon {
    position: absolute;
    top: -50px;
    left: 25%;
  }

  .trending-text1 {
    font-size: 10px;
    align-items: center;
    display: flex;
  }

  .footer-mobile-screen {
    text-align: center;
  }

  .footer-bottom-mobile {
    display: flex !important;

    flex-direction: column-reverse;
  }

  .explore-sharebutton {
    font-size: 11px;
  }

  .form-box {
    margin-top: 50px;
  }

  .containerxyz {
    margin-top: 50px;
  }

  .mobile-footer {
    margin-top: 50px;
  }

  .bottom-text {
    font-size: 12px;
  }

  .bottom-heading {
    font-size: 22px;
  }

  .helpsection-main {
    flex-wrap: wrap;

    border: 1px solid #dddddd;

    margin-bottom: 30px;

    width: 100%;
  }

  .helpsection-img {
    width: 100%;
  }

  .helpsection-content {
    width: 100%;

    margin-left: 0px;

    margin-top: 0px;

    padding: 10px;
  }

  /* ############ Self-Paced Course ############  */
  .bottom-heading {
    font-size: 16px;
  }

  .bottom-text {
    font-size: 10px;
  }

  .btn-v {
    display: inline-block;
    padding: 0.5rem;
    border-radius: 10rem;
    color: black;
    font-size: 10px;
    color: white;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;
    margin-top: 23px;
  }

  /* ################# Login & SignUp Mobile Responsive ##############  */
 

  .mobile-login-email {
    font-size: 12px;
  }

  .css-pp81nz-MuiButtonBase-root-MuiButton-root {
    width: 100px !important;
    font-weight: 700 !important;
    margin-left: 0px !important;
    height: 43px !important;
  }

  .css-lokum1-MuiButtonBase-root-MuiTab-root {
    font-size: 30px !important;
    font-family: var(--font-raleway) !important;
    font-weight: 700 !important;
  }

  .css-xcjkmj-MuiButtonBase-root-MuiButton-root {
    width: 100px !important;
    margin-left: 0px !important;
    height: 40px !important;
    font-family: var(--font-raleway) !important;
    background-color: #d3f433 !important;
    margin-top: 5% !important;
    font-weight: 700 !important;
  }



}

.css-xcjkmj-MuiButtonBase-root-MuiButton-root .css-pp81nz-MuiButtonBase-root-MuiButton-root {
  background-color: #d3f433 !important;
  font-family: var(--font-raleway) !important;
  height: 41px !important;
  width: 130px !important;
  margin-top: 5% !important;
}

.team-row-block .our-team-card {
  flex-wrap: wrap;
  justify-content: center;
}

.tr-wrap2 div {
  width: 20%;
}

.tr-wrap2 {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  width: 100%;
  margin: 0;
  overflow-y: hidden;
  z-index: 5;
  position: relative;
}

@media (max-width: 991px) {
  .offcanvas-header {
    display: block !important;
  }
}

.container.question-main-container {
  max-width: 80% !important;
}

@media (min-width: 767px) {
  .question-category {
    width: 75%;
  }
}

body {
  overflow: auto !important;
  padding-right: 0px !important;
}

.navbar-toggler-icon.white-icon {
  background-image: url(./assets/white\ hamburger\ icon.png) !important;
}

.offcan-head {
  display: flex;
  justify-content: space-between;
}

.card-div:before {
  display: none;
}

@media (max-width: 767px) {
  .explore-main.row {
    margin: 0 -12px;
  }
}

.navbar-toggler:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

@media (max-width: 991px) {

  .explore-main .col-md-8,
  .explore-main .col-md-4 {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .tr-wrap2 div {
    width: 78%;
  }
}

/* ############ Signup page #################### */
.signup-forgetpassword {
    text-decoration: underline;
    font: normal normal normal 14px/19px Raleway;
    letter-spacing: 0px;
    color: #3855F4;
    margin-bottom: 0 !important;
}

.login-contentpage.container {
  max-width: 60% !important;
}

.button {
  display: inline-block;
  border-radius: 10rem;
  color: black;
  font-size: 14px;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
}

.login-heading {
  display: flex;
  justify-content: flex-start;
  font-size: 40px;
  font-weight: 700;
}

.signup-termpolicy {
  font-size: 12px;
}

.login-logo-white {
  width: 45%;
}

.slider-cnt-heading {
  color: white;
  font-size: 70px;
}

.slider-cnt-Subheading {
  color: white;
  font-size: 16px;
}

.login-wrapp .carousel-indicators {
  bottom: 55px !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100% !important;
  justify-content: center !important;
}

.bye-signup {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.login-bottom {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.person-text-signup {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.term-policy-signup {
  margin-left: 5px;
}

.login-wrapp .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 40px;
  height: 8px;
}

.g-recaptcha {
  width: 100% !important;
  height: 100% !important;
}

/* ######################### Login Page ##################### */

.login-forgetpassword {
  color: #3855f4;
  text-align: end;
  font-size: 12px;
}

.login-footer {
  display: block;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.login-socialmedia {
  margin-bottom: 40px;
  margin-top: 15px;
}

.login-socialmedia div img {
  height: 50px;
  padding: 6px;
}

.login-remember {
  margin-left: 5px;
}

/* ################## Verify Your Mail ##############  */
.verify-heading {
  font-size: 30px;
  font-weight: 700;
}

.verify-still {
  margin-top: 20px;
  font-size: 14px;
}

.verify-email {
  text-align: center;
}

.verify-text {
  font-size: 14px;
  font-weight: 600;
}

.verify-still-text {
  font-size: 16px;
  font-weight: 800;
  margin-top: 15px;
}

.selfpace-right-text {
  display: flex;
}

.selfpace-preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.selfpace-time-duration {
  display: flex;
  justify-content: space-around;
  max-width: 56%;
  font-size: 14px;
}

.selfpace-button {
  display: flex;
}

.selpace-left-img {
  width: 100%;
  object-fit: cover;
}

.selpace-left-img-course1 {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 5%;
}

.selpace-left-img-small {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3%;
}

.selfpace-star {
  width: 20px;
}

.slefpace-heading {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 3px;
}

.slefpace-heading-small {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 3px;
  margin-top: 10px;
}

.preview-text {
  margin-left: 10px;
}

.selfpace-button1 {
  margin-right: 15px;
}

.selfpace-top-left {
  position: absolute;
  font-weight: 800;
  top: 20%;
  z-index: 99;
  font-size: 31px;
  left: 70px;
}

.self-know-more {
  padding: 0.75rem 3.5rem !important;
  background-color: white !important;
}

/* ##################### Checkout ###################  */
.checkout-heading {
  display: flex;
  font-size: 20px;
  font-weight: 800;
}

.checkout-card-heading {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  color: #565c83;
}

.checkout-form {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
}

.checkout-form-input {
  margin-top: 15px;
}

.checkout-form-sub {
  border-bottom: 3px solid #565c83;
  width: 150px;
}

.checkout-subtotal {
  display: flex;
  justify-content: space-between;
}

.small-img {
  height: 12px;
    margin-right: 10px;
}

.right-side-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-title {
  padding: 0 2px;
    position: absolute;
    top: -9px;
    left: 15px;
    font-size: 12px;
    font-weight: 600;
    background-color: #ffffff;
}

.header-nav nav .nav .active {
  border-color: transparent !important;
  background-color: transparent !important;
  border-bottom: 3px solid #565c83 !important;
}

.account-download-icon {
  margin-left: 30px;
}

/* ############## In-person Course ############  */
.person-heading {
  font-size: 30px;
  font-weight: 700;
}

.person-list {
  line-height: 50px;
}

.person-bottom-img {
  margin-top: 50px !important;
}

.inperson {
  background-color: #3855f4 !important;
}

.inperson.btn-v::after {
  background-color: #3855f4;
}

.inperson-bottom-email {
  width: 100%;
  height: 130px;
  margin-top: 20%;
  opacity: 0.7;
}

.inperson-bottom-box {
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  background-color: #d3f433;
  padding: 4%;
  border-radius: 15px;
  position: relative;
  right: 0 !important;
}

.inperson-further {
  margin-bottom: 0;
}

.inperson-list .person-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.inperson-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.person-list::before {
  content: "•";
  color: #3855f4;
  font-weight: bold;
  width: 1em;
  margin-left: -1em;
  font-size: 40px;
}

.inperson-course-heading {
  font-size: 24px;
  font-weight: 800;
}

.chanage-in-div {
  width: 80% !important;
}

.myaccount-top-box {
  align-items: center;
  box-shadow: 0px 6px 45px #00000029;
  z-index: 14;
  border-radius: 15px;
  padding: 25px;
}

.overlay-text {
  transform: translateY(-5rem);
  background-color: #ffffff;
  border-radius: 15px;
}

.topbox-right {
  align-items: center;
}

.howcanwehelp-top-left-double-headig,
.howcanwehelp-top-left {
  position: absolute;
  font-weight: 700;
  top: 27%;
  z-index: 99;
  font-size: 32px;
  left: 11%;
}

.line-blue {
  border-bottom: 1px solid black;
}

p span {
  display: block;
}

.account-button {
  display: flex;
}

.account-double-button {
  margin-left: 20px;
}

.vl {
  border-left: 2px solid black;
  height: 100px;
  width: 1% !important;
  opacity: 0.3;

}

.account-text {
  font-size: 16px;
  font-weight: 600;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px 20px;
}

.table-acount {
  background-color: #f8f9ff;
}

.account-border {
  border-bottom: 1px solid #dddddd76;
  border-top: 1px solid #dddddd76;
  opacity: 1;
}

.account-table-heading {
  display: flex;
  justify-content: space-between;
}

.purchase-heading {
  font-size: 20px;
  font-weight: 800;
}

.course-heading {
  border-bottom: 1px solid #dddddd;
  color: #565c83;
}

.course-left {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}

.course-left-text {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 600;
}

.module-heading {
  font-size: 20px;
  font-weight: 800;
}

/* ##################### Checkout ###################  */
.checkout-heading {
  display: flex;
  font-size: 20px;
  font-weight: 800;
}

.checkout-card-heading {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  color: #565c83;
}

.checkout-form {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  flex-wrap: wrap;
}

.checkout-form-input {
  margin-top: 5px;
}

.checkout-form-sub {
  border-bottom: 3px solid #565c83;
  width: 150px;
}

.checkout-order-summary {
  background-color: #f7f7f7;
}

.checkout-order-box {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.checkout-order-box:nth-last-child(1) {
  margin-bottom: 0;
}

.checkout-subtotal {
  display: flex;
  justify-content: space-between;
}

/* ########## Course1 #########  */

.four-box {
  display: flex;
}

.course-small-box {
  width: 100%;
}

.course-certificate-box ul li {
  width: 50%;
  margin-bottom: 20px;
}

.course-certificate-box ul {
  display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    margin-top: 20px;
}

.course-certificate-box {

  display: flex;
  justify-content: space-between;
}

.abcd {
  margin-right: 5% !important;
}

.top-line-p {
  font-size: 16px;
  font-weight: 500;
}

.text-style-b {
  font-size: 20px;
  font-weight: 600;
}

.text-style-l {
  font-size: 13px;
  font-weight: 500;
}

.circle-numbr {
  font-size: 22px;
  padding: 0px 0px 0px 1px;
  font-weight: 600;
  border-radius: 50%;
  background-color: #d3f433;
  text-align: center;
}

.text-style-bo {
  font-size: 18px;
  font-weight: 600;
}

.text-style-mid {
  font-weight: 600;
}

.text-style-lo {
  font-size: 15px;
  font-weight: 500;
}

.sub-text-course1 {
  margin-top: 20px;
}

.card-div {
  width: 25%;
}

.card-style {
  padding: 2em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.center-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}

.center-pro {
  align-items: center;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin: 3%;
}

.video-div-set {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.img-head-set {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.certificate-div {
  position: relative;
}

.down-down-hide {
  visibility: hidden;
}

.down-down-hide-down {
  visibility: hidden;
  height: 220px;
}

.instructor-main-box {
  background-color: #f7f7f7;
}

/* ########## Course1 #########  */

.four-box {
  display: flex;
}

.course-small-box {
  width: 100%;
}

.course-certificate-box {

  display: flex;
  justify-content: space-between;
}

.abcd {
  margin-right: 5% !important;
}

.top-line-p {
  font-size: 16px;
  font-weight: 500;
}

.text-style-b {
  font-size: 20px;
  font-weight: 600;
}

.text-style-l {
  font-size: 13px;
  font-weight: 500;
}

.circle-numbr {
  font-size: 22px;
  padding: 0px 0px 0px 1px;
  font-weight: 600;
  border-radius: 50%;
  background-color: #d3f433;
  text-align: center;
}

.text-style-bo {
  font-size: 18px;
  font-weight: 600;
}

.text-style-mid {
  font-weight: 600;
}

.text-style-lo {
  font-size: 15px;
  font-weight: 500;
}

.sub-text-course1 {
  margin-top: 20px;
}

.card-div {
  width: 25%;
}

.card-style {
  padding: 2em;
  -moz-box-shadow: 0px 0px 21px #00000019;
  -webkit-box-shadow: 0px 0px 21px #00000019;
  box-shadow: 0px 0px 21px #00000019;
  border-radius: 10px;
}

.center-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}

.center-pro {
  align-items: center;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin: 3%;
}

.video-div-set {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.img-head-set {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.img-head-set {}

.certificate-div {
  position: relative;
}

.down-down-hide {
  visibility: hidden;
}

.down-down-hide-down {
  visibility: hidden;
  height: 220px;
}

.first-img {
  top: 68px;
  right: 48px;
}

.second-img {
  right: 122px;
  bottom: 28px;
  z-index: 1;
}

.third-img {
  top: 27px;
  left: 47px;
}

.fourth-img {
  z-index: 1;
  left: 0px;
  bottom: 30px;
}

.first-img,
.fourth-img  {
  width: 120px;
  height: 120px;
}

.second-img,
.third-img {
  width: 200px;
  height: 200px;
}

.first-img,
.fourth-img,
.second-img,
.third-img {
  border-radius: 10px;
  object-fit: cover;
  position: absolute;
}

.instructor-main-box {
  background-color: #f7f7f7;
}

/* ####################### Introduction #####################  */

.selpace-left-img-intro {
  width: 100%;
  height: 332px;
  object-fit: cover;
  border-radius: 5%;
}

.intro-main-box {
  width: 100%;
  height: 486px;
  object-fit: cover;
}

.intro-button {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.intro-download-icon {
  margin-right: 10px;
}

.intro-button-text {
  font-size: 15px;
  font-weight: 600;
}

.intro-top-button {
  display: flex;
  justify-content: flex-end;
}

.intro-main-text {
  font-weight: 600;
}

/* ################# Module 01 #############  */
.module-hours {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.module-duration {
  margin-right: 40px;
  font-weight: 600;
}

.module-3-heading {
  display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    font-size: 18px;
    font-weight: 800;
    padding: 10px 20px;
}

.nav-tab-wrap.with-scroll .nav-pills h2 {
  font-size: 24px;
  font-weight: bold;
}

.module-text-section {
  display: flex;
}

.module-main-3-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f5;
}

.module-3section-text {
  font-size: 16px;
  font-weight: 700;
}

.module-3section-img {
  margin-right: 20px;
}

.assignment-module-text {
  display: flex;
}

.assignment-button {
  display: inline-block;
  padding: 6px;
  border-radius: 25%;
  color: black;
  font-size: 10px;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  margin-left: 20px;
}

.assignment-button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffd41f;
  border-radius: 10rem;
  z-index: -2;
}

.assignment-indicator {
  background-color: #ffd41f;
  padding: 0px 17px;
  border-radius: 12px;
  max-width: 80px !important;
  height: 20px;
  text-align: center;
  font-size: 12px;
  margin-left: 20px;
}

.assignment-indicator-green {
  background-color: #3ece12;
}

.assignment-indicator-red {
  background-color: #de7a30;
}

.assignment-indicator-orange {
  background-color: #FFD41F;
}

.assignment-indicator-green,
.assignment-indicator-red,
.assignment-indicator-orange  {
  padding: 0px 17px;
  border-radius: 12px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  margin-left: 20px;
  text-transform: capitalize;
}


.sub-module-heading-main {
  font-weight: 800;
}

/* ########## Cart Preview ###########  */
.cart-buttons {
  display: flex;
  justify-content: flex-end;
}

.cart-preview-course {
  font-weight: 800;
  font-size: 20px;
}

.cart-self-heading {
  font-size: 22px;
  font-weight: 700;
}

.cart-amount {
  font-size: 22px;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif !important;
}

.cart-inclusive-text {
  font-size: 12px;
}

.cart-selfpace-box {
  padding: 20px;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 10px;
}

.cart-order-heading {
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
}

.cart-order-subtotal {
  font-size: 14px;
  font-weight: 700;
}

.cart-order-total {
  font-size: 22px;
  font-weight: 800;
  margin-top: 15px;
}

.cart-order-button {
  margin-bottom: 15px !important;
}

.thanksfeedback {
  font-size: 26px;
  font-weight: 800;
  margin-top: 30px;
}

.payment-yellow-button {
  display: inline-block;
  padding: 10px 22px;
  border-radius: 10rem;
  color: black;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  margin-top: 9px;
}

.payment-yellow-button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d3f433;
  border-radius: 10rem;
  z-index: -2;
}

.payment-btn-v transparent {
  display: inline-block;
  padding: 7px 10px;
  border-radius: 10rem;
  color: black;
  font-size: 13px;
  font-weight: 700;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  margin-top: 13px;
  border: 1px solid #565c83;
}

.payment-heading {
  font-weight: 800;
  font-size: 32px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.policy-privacy {
  font-family: var(--font-raleway);
}

.policy-privacy-subpage {
  font-size: 20px;
}

.policy-privacy-heading {
  font-weight: 800;
}

.ploicy-number {
  font-size: 25px;
}

.my-profile-navbar-tab {
  font-size: 25px;
  font-weight: 800;
}

.my-profile-name-heading {
  font-size: 26px;
  font-weight: 800;
}

.my-profile-name-heading-subtext {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.my-profile-upperbox-subtext {
  font-size: 13px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif !important;
}

.my-profile-upperbox-content {
  font-size: 14px;
  font-weight: 500;
}

.my-profile-download-icon {
  padding: 5%;

}

.my-profile-number {
  font-family: 'Times New Roman', Times, serif;

}


.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--theme-white) !important;
  box-shadow: none !important;
}

button:focus:not(:focus-visible) {
  outline: none !important;
}

.accordion-button {
  outline: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.card-wrap {
  padding: 20px;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 10px;
  margin-bottom: 20px;
}

.btn-success {
  --bs-btn-color: #565C83 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-hover-color: #565C83 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #fff !important;

}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: #fff !important;

}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #565C83 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
  border-bottom: 1px solid #565C83;
}

.course-image-colage .col-md-6:nth-child(1) img.ms-3 {
  width: 100px;
}


/* ===========================18 April, 2023=========================== */
.tn-wrap .slide {
  -moz-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 100%;
}

.tn-wrap .trending-text1 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.accordion-button::after {
  display: none;
}

.accordion-button::before {
  content: '\002B';
    position: absolute;
    right: 5px;
    top: 50%;
    font-size: 45px;
    font-weight: normal;
    transform: translateY(-50%);
    color: #565C83;
}

.accordion-button:not(.collapsed)::before {
  content: '\002D';
}

.accordion button[aria-expanded="true"],
.accordion button:hover,
.accordion button:focus {
  color: inherit;
}

.accordion button[aria-expanded="true"] {
  border-bottom: 1px solid #dddddd !important;
}

.accordion-body {
  padding-left: 0;
}

.accordion button span {
  max-width: 96%;
    display: inline-block;
}

.category-itmes:hover #hidden-arrow {
  width: 15px;
  height: 15px;
}

.div-line {
  width: 140px;
  background-color: #ffffff;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.carousel-indicators button.active,
.slick-dots li button.active {
  background-color: #3855F4 !important;
}

.what-uni-div {
  margin: 0 !important;
}

.slider-div-box {
  padding: 58px 84px !important;
}

.hcwh-home .section2-heading.gap-top {
  margin-top: 78px !important;
}

.line {
  border-top: 2px solid #565C83;
  opacity: 1;
}

p {
  color: #000000;
}

.first-card-style {
  margin: 0;
}

.first-slider .card > div:nth-child(2) {
  padding: 30px 35px;
}

.first-pera-style {
  margin-left: 0;
}

.first-slider .card {
  min-height: 364px !important; 
  height: auto !important;
}

.wps-home .wrapper-slider-new {
  margin-top: 30px;
}

.wrapper-slider-new {
  height: 56vh;
}

.para-text {
  padding: 0 64px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.section2-heading-our-clnt h1 {
  margin-bottom: 0;
}

.our-clnt-slider {
  margin-top: 20px;
}

.tr-wrap2 div {
  padding: 0;
}

.followus-footer {
  margin-bottom: 1rem !important;
  padding-left: 0;

}

footer p,
.carousel-caption p {
  color: #ffffff;
}

.slider-div-box {
  width: 71%;
    margin: 0 auto;
    margin-top: -84px;
}

.carousel-indicators {
  bottom: 179px !important;
}

.selpace-left-img-small {
  height: 124px;
}

.bc-wrap span {
  font-size: 18px;
  color: #565C83;
}

.bc-wrap {
  margin-top: 56px;
}

.b-prev-p-title {
  color: #AAAAAA !important;
}

.vision-card-text {
  height: auto;
  padding: 40px 30px;
}

.section2-heading-our-clnt h2 {
  margin-bottom: 0;
}

.slider-unique .container,
.our-client .container {
  margin-top: 30px;
}

.bottom-box {
  right: auto;
  padding: 40px;
  justify-content: space-between;
}

.botton-image {
  width: 86px;
  height: auto;
  filter: brightness(0) invert(1);
  opacity: 0.5;
}

.further-box .your-button,
.further-box  .btn-v {
  background-color: #3855F4;
  font-weight: 600;
  color: #ffffff !important;
}

.bottom-heading {
  margin-bottom: 0;
}

.inperson-list {
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}

.inperson-list .person-list {
  width: 100%;
}

.inperson-bottom-box.lg .botton-image {
  width: 106px;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

.MuiButton-root {
    background: #D3F433 !important;
    font-weight: 600 !important;
}

#phone::-webkit-input-placeholder { /* Edge */
  font-family: 'Lato', sans-serif;
}

#phone:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Lato', sans-serif;
}

#phone::placeholder,
.lato {
  font-family: 'Lato', sans-serif;
}

.person {
  top: 18px;
  bottom: auto;
  width: 20px;
}

.bye-signup .person {
  top: 12px;
}

.bye-signup.wtu-mobile-block .person {
  top: 26px;
}

.textarea-block .person {
  top: auto;
  bottom: 8px;
}

textarea.Person-text1 {
  padding-right: 30px;
  overflow: hidden;
}

.react-tel-input .form-control {
  box-shadow: none !important;
}

p > .MuiButtonBase-root {
  background: transparent !important;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 26px;
}

p.MuiTypography-root,
p#modal-modal-description,
.MuiTypography-root,
.MuiButtonBase-root {
  text-align: center;
  margin: 0 auto !important;
}

.MuiButtonBase-root {
  margin-top: 20px !important;
}

.MuiBox-root {
  text-align: center;
}

.selpace-left-img {
  height: 100%;
  border-radius: 10px;
}

.selfpace-time-duration p > b {
  display: block;
}

.selfpace-time-duration p {
  text-align: left;
}

.selfpace-time-duration {
  justify-content: space-between;
}

.selfpace-star {
  margin-right: 5px;
}

.circle-numbr {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav .nav-link.active {
  color: var(--theme-white) !important;
  color: #d3f433 !important;
}

.nav-link-grow-up.active::before {
  height: 3px;
}

.oc-slider .slick-slide {
  margin: 0;
}

.oc-slider .slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 118px;
  text-align: center
}

.oc-slider .slick-slide > div > div img {
 max-height: 100px;
  margin: 0 auto;
  max-width: 113px;
}

.slick-slider.oc-slider .slick-track, 
.slick-slider.oc-slider .slick-list {
  padding-top: 0px !important;
  padding-bottom: 0px;
}

.slick-next:before, 
.slick-prev:before {
    display: none;
}

.slick-prev::after, 
.slick-next::after {
  content: '';
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: -13px;
}

.slick-prev, 
.slick-next {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  background-color: var(--theme-parrot) !important;
  margin: 0 !important;
}

.slick-next::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slick-prev {
    left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.companyinfo-cnt-wrap ol,
.companyinfo-cnt-wrap ul {
  margin-top: 20px;
}

.companyinfo-cnt-wrap ul {
  margin-bottom: 20px;
}

.companyinfo-cnt-wrap li {
  margin-bottom: 10px;
}

.circle-numbr {
  margin-left: -6px;
  line-height: 1;
  font-family: 'Lato';
}

.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5 !important;
}

.accordion-item {
  border: 0 !important;
}

.accordion .accordion-item button[aria-expanded="true"],
.accordion .accordion-item button {
  border-color: #e5e5e5 !important;
}

.wps-icon img {
  display: block;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  object-fit: cover;
  border: 1px solid #dddddd;
}

.wps-icon {
  left: 50%;
  transform: translateX(-50%);
}

.csb-slider-block .slick-slide > div {
  margin-right: 10px;
  margin-left: 10px;
}

.csb-slider-block.slick-slider .slick-list  {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.first-pera-style {
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden;
}

.lc-2{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;  
overflow: hidden;
}

.center-slider p.wps-pera {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.cd-wps .wps-name-postion {
  flex-wrap: wrap;
  text-align: center;
}

.my-instructor-block > .myi-image-block img {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 1px solid #dddddd;
  object-fit: cover;
} 

.my-instructor-block {
  margin: 0 !important;
  padding: 10px;
}

.course-instructor > div {
  margin-right: 10px;
}

.course-instructor .slick-slider .slick-track, 
.course-instructor .slick-slider .slick-list {
  padding-top: 0 !important;
  padding-bottom: 20px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.my-instructor-block .col-md-4.myi-image-block {
  flex: 0 0 auto;
    width: 33.33333333%;
}

.my-instructor-block .col-md-8{
  flex: 0 0 auto;
    width: 66.66666667%;
}

.crcl-img-block {
  width: 250px;
  height: 250px;
  position: relative;
  margin: 0 auto;
}

.crcl-img-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 7px solid #d3f433;
  border-radius: 100%;
}

.crcl-img-block::after {
  content: '';
    position: absolute;
    bottom: 30px;
    right: 11px;
    width: 40px;
    height: 40px;
    background-color: #d3f433;
    border-radius: 100%;
    display: inline-block;
    z-index: 1;
}

.center-slider .slick-slide > div .staars + div .para-text{
  min-height: 66px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.login-slider .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-signup-wrapp {
  /* height: 100vh; */
  display: flex;
  align-items: start;
  justify-content: center;
}

.login-slider {
  padding: 0 !important;
}

.login-signup-wrapp .MuiButtonBase-root {
  font: normal normal bold 32px/60px Raleway !important;
letter-spacing: 0px !important;
color: #000000 !important;
  opacity: 0.2 !important;
  padding: 0 !important;
  text-transform: capitalize !important;
}

.login-signup-wrapp button.Mui-selected {
  color: #000000 !important;
  opacity: 1 !important;
}

.login-signup-wrapp .MuiTabs-flexContainer > button {
  margin-right: 30px !important;
}

.login-signup-wrapp .MuiTabs-flexContainer > button:nth-last-child(1) {
  margin-right: 0 !important;
}

.login-signup-wrapp p.MuiTypography-root, 
.login-signup-wrapp p#modal-modal-description, 
.login-signup-wrapp .MuiTypography-root, .MuiButtonBase-root,
.signup-termpolicy label {
  text-align: left;
  margin: 0 0 !important;
  font: normal normal 600 14px/19px Raleway;
    letter-spacing: 0px;
    color: #000000;
    padding: 0 !important;
}

.login-signup-wrapp > div {
  width: 55%;
  padding-top: 30px;
}

.login-signup-wrapp > div .MuiBox-root {
  text-align: left;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.lt-block {
  margin-bottom: 40px;
}

.ls-tabs .signup-termpolicy {
  padding-left: 0 !important;
}

.login-signup-wrapp .MuiButtonBase-root.btn-ls {
  opacity: 1 !important;
    font: normal normal 600 16px/16px Raleway !important;
    padding: 15px 40px !important;
    height: auto;
    min-width: auto !important;
    width: auto !important;
}

.login-signup-wrapp .ls-tabs .signup-termpolicy .MuiButtonBase-root {
  margin-right: 5px !important;
}

p.login-footer-text {
  font: normal normal normal 16px/20px Raleway;
  letter-spacing: 0px;
  color: #707070;
}

.navbar-nav .MuiAvatar-circular {
  width: 30px;
  height: 30px;
}

.navbar-nav svg {
  width: 20px;
}

.navbar-nav .MuiBadge-badge {
  width: 15px !important;
  height: 15px !important;
  font-size: 10px !important;
  min-width: 15px;
  background-color: #ffffff;
  color: #000000;
  top: 4px;
}

.login-profile-name {
  font-size: 14px !important;
}

.login-welcome-texckt-block.MuiTypography-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.login-profile-wrap {
  display: flex;
  align-items: center;
}

.login-profile-wrap .MuiAvatar-circular {
  margin-right: 10px;
}

.login-profile-wrap .MuiAvatar-circular > img {
  border-radius: 100%;
}

.hdr-drop-dwon-block ul {
  padding: 0 !important;
}

.hdr-drop-dwon-block li {
  padding: 7px 20px !important;
}

.hdr-drop-dwon-block li,
.hdr-drop-dwon-block li p {
  font-size: 12px !important;
}

.inner-navbar .navbar-nav .MuiBadge-badge {
  background-color: #d3f433;
}

.frame-img-cnt-block li {
  margin-bottom: 20px;
}

.frame-img-cnt-block li:nth-last-child(1) {
  margin-bottom: 0;
} 

.course-certificate-box {
  margin-bottom: 20px;
}

.write-to-us-wrap button,
.MuiButton-root {
  padding: 5px 15px !important;
  border-radius: 20px;
}

.slick-disabled {
  opacity: 0.5;
}

.our-team-slider-wrap,
.our-team-slider-wrap.slick-slider .slick-track, 
.our-team-slider-wrap.slick-slider .slick-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.slick-slide {
  margin: 0 !important;
}

.form-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 50px;
}

.form-title span {
  position: relative;
}

.form-title span::after {
  content: '';
    width: 44%;
    height: 2px;
    background-color: #565C83;
    position: absolute;
    bottom: -15px;
    left: 0;
}

.form-title.text-center span::after {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.form-wrap label {
  width: 100%;
  display: block;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}

.form-wrap input {
  width: 100%;
  border: 0;
  display: block;
  border-bottom: 1px solid #707070;
  outline: 0;
  font-size: 12px;
}

.btn-v {
    font: normal normal 600 14px/16px Raleway !important;
    padding: 15px 40px !important;
    color: #000000 !important;
    background-color: #d3f433;
    border-radius: 50px !important;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
    text-decoration: none !important;
    margin-right: 20px;
    text-align: center;
    display: inline-block;
}

.btn-v.transparent {
  background-color: transparent;
  border: 1px solid #565C83 !important;
  color: #565C83 !important;
}

.btn-v:nth-last-of-type(1) {
  margin-right: 0;
}

.top-box-info-block p span {
  word-wrap: break-word;
}

.mt--4 {
  margin-top: 40px !important;
}

.mt--5 {
  margin-top: 50px !important;
}

.green-tick-img-block img {
  width: 68px;
}

.green-tick-img-block {
  margin-right: 10px;
}

.bt {
  color: #3855F4 !important;
}

.top-box-info-block::before {
  content: '';
  position: absolute;
  top: 0;
  left: -38px;
  height: 100%;
  width: 1px;
  background-color: #dddddd;
}

.nav-tab-wrap .nav-tabs .nav-link {
  border: 0 !important;
  /* font-size: 14px !important; */
  color: #8A8A8A !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 40px;
}

.nav-tab-wrap .nav-tabs .nav-link:nth-last-child(1) {
  margin-right: 0 !important;
}

.nav-tab-wrap .nav-tabs {
  border: 0 !important;
  border-bottom: 1px solid #dddddd !important;
}

.nav-tab-wrap .nav-tabs .nav-link.active {
  color: #565C83 !important;
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}

.nav-tab-wrap.with-scroll .nav-tabs {
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}

.nav-tab-wrap.with-scroll li.nav-item {
  min-width: 172px;
}

.nav-tab-wrap .nav-tabs .nav-link.active {
  color: #3855F4 !important;
}

.modal-wrap p.MuiTypography-root, 
.modal-wrap p#modal-modal-description, 
.modal-wrap .MuiTypography-root, 
.modal-wrap .MuiButtonBase-root {
  text-align: left;
  margin: 0 !important;
}

.select-block {
  border: 1px solid #dddddd;
  height: 40px;
  padding: 10px;
  outline: 0 !important;
  width: 100%;
}

.modal-wrap .bye {
  margin-bottom: 20px;
}

.error-text {
  font-size: 12px;
}

.select-wrap {
  position: relative;
}

.select-wrap::after {
  content: '';
  border: solid #8A8A8A ;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: 15px;
  right: 10px;
}

.select-block {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.cross-icon-block {
  position: absolute;
    top: 15px;
    right: 15px;
}

.cross-icon-block img {
  width: 15px;
}

.modal-wrap h2 {
  font-weight: bold;
}

a.read-more {
  text-decoration: none;
}

.card-moduke-wrap a.read-more {
  margin-left: 25px;
}

.card-moduke-wrap .col-6 {
  margin-top: 0;
  font-size: 13px;
  margin-bottom: 10px;
}

.module-heading {
  margin-bottom: 15px;
}

.module-heading a {
  color: #000000 !important;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #475dd5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #475dd5;
}

.lighter-scroll ::-webkit-scrollbar {
  width: 3px;
}

.book-session-cnt {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #f7f8ff;
  padding: 15px;
  -moz-box-shadow: 0px 0px 6px #00000029;
  -webkit-box-shadow: 0px 0px 6px #00000029;
  box-shadow: 0px 0px 6px #00000029;
  min-width: 400px;
  z-index: 5;
}

.book-session:hover .book-session-cnt {
  display: block;
}

.bsc-titlte {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #000000;
}

.bsc-date-label {
  font-size: 12px;
}

.bsc-date {
  font-size: 14px;
}

.bsc-date-label,
.bsc-titlte,
.bsc-date,
.book-session-cnt a {
  color: #000000;
}

.nav-tab-wrap  .nav-pills .nav-link {
  color: #707070;
}

.nav-tab-wrap .nav-pills .nav-link.active, 
.nav-tab-wrap .nav-pills .show>.nav-link {
  background-color: transparent;
}

.nav-tab-wrap .nav-pills .nav-link.active {
  color: #3855F4;
}

.book-session > span {
  pointer-events: none;
}

.btn-v:after {
  display: none !important;
}

.intro-top-button {
  margin-bottom: 40px;
}

.btn-v.sm {
  /* padding: 10px 20px !important; */
  font-size: 14px !important;
}

h2.title,
h2.message-heading {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0;
}

.with-scroll >  .nav-pills {
  flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.with-scroll >  .nav-pills  .nav-item a {
  min-width: 288px;
  color: #ffffff;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #3855f4;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
}

.with-scroll >  .nav-pills  .nav-item a.active {
  background-color: #3855f4;
}

.with-scroll >  .nav-pills  .nav-item a {
  color: #3855f4 !important;
}

.with-scroll >  .nav-pills  .nav-item a.active  {
  color: #ffffff !important; }


.hdr-link-title,
.hdr-link-subtitle {
  color: #ffffff;
  text-align: left;
}

.login-profile-wrap .dark .hdr-link-title,
.login-profile-wrap .dark .hdr-link-subtitle {
  color: #000000;
}

.hdr-link-subtitle {
  font-size: 14px;
}

.hdr-dropdown-contents {
  position: absolute;
  top: 120%;
  left: 0;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 1;
  min-width: 150px;
  padding: 10px 0;
}

.hdr-dropdown-contents li,
li.drp-sub-link p {
  font-size: 14px !important;
  text-align: left !important;
  margin: 0 !important;
  cursor: pointer;
}

.hdr-dropdown-contents li {
  padding: 5px 15px !important;
}

.hdr-dropdown-link {
  cursor: pointer;
}

.logout-modal-block .btn-v {
  background-color: #d3f433 !important;
}

.selfpace-button1.helpsection-container p {
  margin-top: 10px;
}

.cook-slot-noteheading {
  margin-top: 10px;
}

.login-signup-wrapp  .signup-termpolicy .MuiButtonBase-root {
  opacity: 1 !important;
  color: #1976d2 !important;
}

.navbar-nav li {
  cursor: pointer;
}

.form-filed input,
.form-filed select {
  width: 100%;
  border: 1px solid #dddddd;
  outline: 0 !important;
  padding: 10px 15px;
  font-size: 14px;
}

.select-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.checkout-form > div {
  width: 49%;
}

.checkout-form label {
  font-size: 14px;
}

p.text-danger {
  font-size: 12px;
  margin-top: 0 !important;
  font-weight: 600;
}

.page-center-div-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 77px);
}

.text-md {
  font-size: 20px !important;
}

.page-center-div-block > div {
  width: 51%;
}

.ps-icon-block img {
  width: 72px;
}

.pymnt-cards-block .file-marker {
  border: 1px solid #dddddd;
  padding: 20px;
  position: relative;
  border-radius: 4px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}

.arrow.sm {
  padding: 3px;
}

.arrow.white {
  border-color: #ffffff;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.module-table th {
  background-color: #f5f5f5;
}

.module-table th {
  font-size: 18px;
  font-weight: 800;
  padding: 10px 20px;
}

.module-table td {
  font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.module-table button {
  margin-bottom: 0;
}

form .bye .person-text {
  text-align: left;
}

.form-filed-group label {
  width: 100%;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.form-filed-group input {
  width: 100%;
}

.form-filed-group {
  margin-bottom: 20px;
}

.hdr-link-title-and-subtitle {
  max-width: 120px;
}

.login-wrapp .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.react-tel-input .selected-flag .arrow {
  border: 0;
  padding: 0;
  display: block;
}

.form-filed-group .react-tel-input .form-control {
  border: 0;
  height: 100%;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6)
}

.hdr-link-title {
  font-size: 12px;
}

.share-feedback-wrapp button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid #707070;
}

.share-feedback-title {
  font-size: 24px !important;
  margin-bottom: 15px;
}

.css-1wnsr1i {
  border: 1px solid #707070 !important;
border-radius: 15px;
  padding: 0 30px 30px 30px !important;
}

.tck-mark-block img {
  width: 50px;
}

.top-12 {
  top: 12px !important;
}

.selfpace-time-duration p br {
  display: none;
}

h2.h2-title{
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.vm-title {
  font-size: 28px;
  font-weight: bold;
}

.vm-wrap {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.video-block .into-main-box {
  height: 100% !important;
}

.vm-info {
  display: flex;
}

.vm-info > div:nth-child(1) {
  margin-right: 60px;
}

.vm-description,
.vm-info {
  font-size: 14px;
}

.assignment-block .assignment-module-text {
  width: 33.33%;
}

.assignment-block > div {
  display: flex;
  flex-wrap: wrap;
}

.assignment-block > div li {
  font-size: 14px;
  font-weight: bold;
  list-style: none;
  position: relative;
  padding-left: 16px;
}

.assignment-block > div li::before {
  content: '';
  width: 7px;
  height: 7px;
  background-color: #405CF5;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}

.assignment-block .assignment-module-text {
  margin-bottom: 30px;
}

.video-block .into-main-box > div {
  position:  relative !important;
}

.notification-block {
  background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    min-width: 200px;
    max-height: 300px;
    overflow-y: auto;
}

.notification-block ul {
  margin: 0;
  padding: 0;
}

.notification-block ul li {
  list-style: none;
  text-align: left;
}

.notification-block ul li a {
  text-decoration: none;
  font-size: 12px;
  color: #000000 !important;
  padding: 10px 0;
  display: block;
  font-weight: 600;
}

.cart-img-block img{
  width: 100%;
  height: 100%;
}

img {
  object-fit: cover;
}

.checkout-order-box button {
  padding: 0 !important;
  margin: 0 !important;
}

.checkout-order-box button img {
  opacity: 0.7;
}

.co-img-cnt-block {
  width: 97%;
}

.co-img-btn-block {
  width: 6%;
}

.co-img-block img {
  width: 60px;
  height: 55px;
  border-radius: 5px;
  min-width: 60px;
}

.order-name-block {
  font-size: 13px;
  font-weight: bold;
}

.red-text {
  color: red !important;
}

.header-nav.gap-top.nav-tab-wrap.with-scroll .tab-content {
  position: relative;
}

.bs-table-wrap {
  background-color: #F7F8FF;
  padding: 10px;
  width: 100%;
}

.btw-title {
  font-size: 20px;
  font-weight: 600;
}

.btw-title-wrap {
  border-bottom: 1px solid #dddddd;
  padding: 10px 0;
  margin: 0 20px;
}

.bs-table-wrap th,
.bs-table-wrap td {
  font-weight: 400;
  color: #000000;
}

.calender-legends ul li {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  list-style: none;
  margin-right: 50px;
}

.calender-legends ul li:nth-last-of-type(1) {
  margin-right: 0;
}

.calender-legends ul li::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #3855F4;
}

.calender-legends ul li.red::before {
  background-color: red;
}

.calender-legends ul li.green::before {
  background-color:green;
}

.calender-legends ul li.yellow::before {
  background-color: orange;
}

.bokg-slot-wrap .select-wrap select {
  border: 0;
  border-bottom: 1px solid #a0a0a0;
  outline: 0 !important;
  width: 100%;
}

.bokg-slot-wrap .select-wrap::after {
  top: 5px;
}

.rdrCalendarWrapper + div {
  display: none;
}

.calender-legends {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
}

.module-table td, 
.module-table th {
  vertical-align: top;
}

.module-table tr td .d-flex {
  align-items: center;
}

.module-table tr td .d-flex .selfpace-preview {
  margin-bottom: 0;
}

.selfpace-right-text svg {
  pointer-events: none;
}

.btw-title {
  width: 50%;
}

.my-account-tab.nav-tab-wrap .nav-tabs .nav-link.active {
  position: relative;
  border-bottom: 2px solid #565C83 !important;
  color: #565C83 !important;
  margin-bottom: -2px !important;
}

.my-account-tab.nav-tab-wrap .nav-tabs .nav-link {
  padding: 20px 0 !important;
  margin-bottom: 0 !important;
}

.my-account-tab.nav-tab-wrap .nav-tabs .nav-link::before {
  display: none !important;
}

.log-out-popup-wrap .MuiPaper-rounded{
  margin: 0 !important;
}

.rdrCalendarWrapper {
  -moz-box-shadow: inset 0px 0px 6px #00000029, 0px 3px 6px #00000029;
  -webkit-box-shadow: inset 0px 0px 6px #00000029, 0px 3px 6px #00000029;
  box-shadow: inset 0px 0px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 15px;
}

ul.book-slot-notes{
  padding-left: 15px !important;
  margin-top: 20px !important;
}

.rdrDayNumber {
  font-weight: 600 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
color: #263238 !important;
}

.rdrSelected, 
.rdrInRange, 
.rdrStartEdge, .rdrEndEdge {
  left: 3px !important;
  bottom: 5px !important;
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
}

.rdrDay {
  width: 50px !important;
  height: 50px !important;
}

.rdrDay .rdrDayNumber::after,
.rdrDayEndPreview {
  display: none !important;
}

.simple-link {
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
}

[type=button]:disabled {
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.assignment-block {
  margin-top: 40px;
}

.explore-sharebutton button {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  padding: 10px 5px;
}

.explore-sharebutton button img {
  width: 25px;
}

.read-more-description {
  cursor: pointer;
}

.line-txt-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.read-more-description {
  font-size: 12px;
  font-weight: 600;
  color: #0a58ca;
}

.our-team-slider-wrap .slick-slide > div {
  min-width: 200px;
}

.btn-v.assignment-btn {
  padding: 15px 24px !important;
  font-size: 12px !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item {
  min-width: 288px;
  margin-right: 10px !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item:nth-last-child(1) {
  margin-right: 0 !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item button{
  width: 100%;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid #3855f4 !important;
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 7px 15px !important;
    font-size: 14px !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item button{
  color: #3855f4 !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item button.active {
  background-color: #3855f4;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs .nav-item button.active {
  color: #ffffff !important;
}

.nav-tab-wrap .modul-tabs-wrap .nav-tabs {
    border-bottom: 0 !important;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}

.module-table tbody, 
.module-table td, 
.module-table tfoot, 
.module-table th, 
.module-table thead, 
.module-table tr {
  border-width: 1px;
  border: 1px solid #dddddd;
}

.table-vt-block,
.table-vd,
.table-vo {
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  max-height: 68px;
  padding-right: 10px;
}

.module-table tr td:nth-last-of-type(1) {
  vertical-align: middle;
}

.module-table img {
  width: 110px;
  min-width: 48px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.tabs-not-clickable.with-scroll > .nav-pills .nav-item a {
  pointer-events: none;
}

.min-select {
  border: 1px solid #dddddd;
  min-width: 264px;
}

.select-wrap.min-select select {
  border: 0 !important;
  width: 100%;
  outline: 0 !important;
  padding: 10px;
}

.notification-block ul li:nth-last-child(1) {
  border-bottom: 0 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.progress-bar,
.Person-text1 {
  font-family: 'Open Sans', sans-serif;
}

.signup-captcha {
  margin-top: 20px;
}

.module-table .table>:not(caption)>*>* {
  padding: 0;
  padding-right: 10px;
}


span.rdrDayNumber > div > .status-dot{
  position: absolute;
  bottom:-5px;
  left: 50%;
  transform: translateX(-50%);
 
  }

  .user-profile-name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 94px;
    white-space: nowrap;
  }



































@media(max-width: 1680px) {
  .slider-div-box {padding: 25px 60px !important;}
  .first-slider .card > div:nth-child(2) {padding: 20px 20px;}
  .gap-top {margin-top: 70px !important;}
  .gap-bottom {margin-bottom: 70px !important;}
  .para-text {padding: 0 30px;}
  .carousel-indicators {bottom: 138px !important;}
  .bc-wrap span {font-size: 14px;}
  .bc-wrap {margin-top: 40px;}
  .circle-numbr {width: 45px; height: 45px; font-size: 18px;}
  body {font-size: 14px !important;}
  
}

@media(min-width: 767px) {
  .csb-slider-block .slick-next {right: -40px !important;}
  .csb-slider-block .slick-prev {left: -40px !important;}
  .certificate-div {width: 100%; min-height: 285px; height: 100%; max-height: 100%;}
  .certificate-div img {width: 100%; height: 100%; object-fit: cover;}
  .crtfct-cnt-wrapp {display: flex; flex-wrap: wrap;}
  .our-team-slider-wrap .slick-prev {left: -20px !important;}
  .our-team-slider-wrap .slick-next {right: -20px !important;}
}

@media(min-width: 991px) {

  .howcanwehelp-top-left-double-headig,
  .howcanwehelp-top-left {left: 43%; transform: translate(-50%, -50%);}

  .cop-consulting-banner-wrap .howcanwehelp-top-left-double-headig {left: 65px; transform: none;}
  .bottom-box.inperson-bottom-box.lg {padding: 40px 40px 20px 40px;}
  .explore-right-img {height: 70px; object-fit: cover;}
  .selpace-left-img {min-height: 375px; max-height: 375px;}
  .our-team-slider-wrap .slick-track {width: 100% !important; display: flex !important; justify-content: space-between;}
}

@media(min-width: 1390px) and (max-width: 1700px) {
  .col-20 {width: 28% !important;}
  .first-slider .card {min-height: 396px !important;}
}

@media(min-width: 1400px) {
  .login-signup-wrapp {align-items: center;}
}

@media(min-width: 1681px) {
  .first-slider .card {min-height: 494px !important;}
  .container {max-width: 1440px !important;}
  .div-size-set-last>div>img {height: 305px;}
  .further-box .bottom-heading {font-size: 40px;}
  .further-box .bottom-text {font-size: 26px;}
  .further-box .your-button {font-size: 22px; width: 27%; padding: 25px; margin-top: 25px;}
  .message-heading {font-size: 48px;}

  .first-img,
.fourth-img  {
  width: 180px;
  height: 180px;
}

.second-img,
.third-img {
  width: 320px;
  height: 320px;
}

  .howcanwehelp-top-left-double-headig,
  .howcanwehelp-top-left {
    font-size: 48px;
  }
} 

@media(max-width: 991px) {
  .assignment-block .assignment-module-text {width: 50%;}

  .howcanwehelp-top-left-double-headig,
  .howcanwehelp-top-left {
    position: relative !important; 
    top: auto !important; 
    font-size: 24px !important; 
    left: auto !important; 
    margin-bottom: 0 !important;
  }

  .carousel-caption p {
    width: 100%;
  }

  .second-img, 
  .third-img {
    width: 110px;
    height: 110px;
}

.first-img, 
.fourth-img {
  width: 80px;
  height: 80px;
}

.first-img {right: 70px;}

  .nav-link::before {display: none;}
  .login-signup-wrapp > div {width: 84%;}
  .carousel-caption p {line-height: 1;}
  .div-line {margin-top: 5px;}
  .page-center-div-block > div {width: 80%;}
  .carousel-indicators {left: 14% !important; bottom: 88px !important;}
}

@media(min-width: 767px) and (max-width: 790px) {
  .second-img, 
  .third-img {
    width: 92px;
    height: 92px;
}

.first-img, 
.fourth-img {
  width: 70px;
  height: 70px;
}
}

@media(min-width: 790px) and (max-width: 830px) {
  .second-img, 
  .third-img {
    width: 95px;
    height: 95px;
}

.first-img, 
.fourth-img {
  width: 72px;
  height: 72px;
}
}

@media(min-width: 767px) and (max-width: 991px) {
  .selpace-left-img {height: auto;}
}

@media(max-width: 767px) {

  .text-md {
    font-size: 14px !important;
}

.chanage-in-div .selfpace-button .btn-v,
span.btn-v {
  width: 100%;
}

  .carousel-caption p {line-height: 1.3;}
  .slder-text-size {font-size: 30px !important;}
  .carousel-caption h5 {font-size: 30px !important;}
  .slder-text-p-size {width: 72%;}
  .slider-div-box {padding: 15px 30px !important; width: 90%;}
  .assignment-block .assignment-module-text {width: 100%;}
  .video-module-cnt-block {margin-top: 10px !important;}
  .btn-v {padding: 10px 20px !important; font-size: 12px !important;}
  .module-table th {min-width: 216px;}
  .justify-content-center-mobile {justify-content: center !important;}
  .share-text-block {width: 100%; text-align: center; margin-bottom: 10px;}
  .d-none-mobile {display: none !important;}
  .explore-share-btn-block {width: 100%; text-align: center;}
  .m-0-mobile {margin: 0 !important;}
  .course-instructor .slick-next {right: 2px !important;}
  .course-instructor .slick-prev {left: 2px !important;}
  .course-instructor > div {margin-right: 0;}
  .my-account-tab.nav-tab-wrap .nav-tabs {display: flex; flex-wrap: nowrap; overflow-y: hidden; overflow-x: auto;}
  .my-account-tab.nav-tab-wrap .nav-tabs .nav-link {min-width: 50%; white-space: nowrap; margin-right: 0 !important;}
  .my-account-tab.nav-tab-wrap .nav-tabs .nav-link.active {border-bottom: 0 !important;}
  .topbox-right {text-align: center; margin-bottom: 10px;}
  .top-box-info-block {text-align: center;}
  .topbox-right .col-md-4 {margin-bottom: 10px;}
  .myaccount-top-box {padding: 15px; width: 88%; margin: 0 auto !important;}
  .ma-wrap.overlay-text {margin-top: 44% !important;}
  
  
  .test-size-slider-down2,
  .section2-heading h1,
  .trnd-heading-last h2,
  .section2-heading-our-clnt h1,
  .vision-card-text h2 {
    font-size: 28px;
  }

  .slefpace-heading {
    font-size: 22px;
  }

  .oc-slider .slick-slide > div > div img{
    max-width: 70%;
  }

  .test-size-slider-down-pera,
  .section-content {
    font-size: 14px;
  }

  p,
  body {
    font-size: 11px !important;
  }

  .section-content {
    width: 83%;
  }

  .first-slider {
    width: 100%;
}

  .container.question-main-container,
  .chanage-in-div {
    width: 100% !important;
    max-width: 100% !important;
}

.second-img, 
  .third-img {
    width: 125px;
    height: 125px;
}

.first-img, 
.fourth-img {
  width: 85px;
  height: 85px;
}

.first-img {right: 70px;}

.center-slider .slick-current.slick-active>div {
  width: 79% !important;
}

.css-1lu902d {
  width: 92% !important;
}

.intro-top-button {
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.intro-top-button > div {width: 100%;}

.intro-top-button .btn-v,
.checkout-form > div {
  width: 100%;
}

.nav-tab-wrap .nav-item {
  min-width: 104px;
}

.nav-tab-wrap.with-scroll .nav-item {
  min-width: auto;
}

.nav-tab-wrap .flex-row.nav.nav-pills {
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
}


  .hcwh-home .section2-heading.gap-top {margin-top: 70px !important;}
  .wrapper-slider-new {height: 42vh;}
  .tr-wrap2 div {width: 92%;}
  .tn-wrap {margin-bottom: 30px;}
  .footer-icon-set-btn {width: 28px;}
  .carousel-indicators {bottom: 170px !important;}

  .howcanwehelp-top-left-double-headig,
  .howcanwehelp-top-left {
    font-size: 16px !important;
  }

  .slick-prev, 
.slick-next {
  width: 30px !important;
  height: 30px !important;
}

.slick-prev::after, 
.slick-next::after {
  padding: 3px;
}

h2.title,
h2.message-heading {
  font-size: 28px !important;
}

.line {
  margin: 10px 0;
}

.selpace-left-img,
.selpace-left-img-intro {
  height: auto; 
  margin-bottom: 10px;
}

.hcwh-home .card:hover .card-body,
.unique-slider .card:hover .card-body {
  padding: 0 50px;
}

  .navbar .container-fluid {margin: 0 !important;}
  .bottom-box {padding: 15px;}
  .accordion button span {max-width: 88%;}
  .explore-righttext {font-size: 16px;}
  .inperson-list .person-list {align-items: start; line-height: 25px;}
.person-list::before {width: auto; margin-right: 10px;}
.mt--sm--30 {margin-top: 30px !important;}
.further-box .btn-v {padding: 10px 30px;}
.selfpace-time-duration {max-width: 100%;}
.selfpace-button {flex-wrap: wrap;}
.account-double-button {margin-left: 0;}
.selfpace-button button {color: #000000; padding: 0.75rem 2.25rem; width: 100%; font-size: 12px;}
.selfpace-button a {width: 100%; margin-top: 10px; font-size: 12px; color: #000000; padding: 0.75rem 2.25rem;}
.selfpace-button > div {width: 100%; text-align: center;}
.selfpace-button1 {margin-right: 0;}
.course-image-colage {flex-wrap: wrap;}
.video-div-set {margin-top: 100px;}
.explore-right-smallbox {margin-top: 10px;}
.slider-div {overflow-x: hidden; width: 92%; margin: 0 auto;}
.left-block {padding: 15px 25px; bottom: -25px;}
.carousel-item {height: 100vh;}
.slider-div-box {margin-top: -30px;}
.first-slider .card {width: 100% !important; margin: 0; min-height: 415px !important;}
.center-slider .slick-slide {margin: 0;}
.wps-icon {left: 50%; transform: translateX(-50%);}
.center-slider .slick-slide {height: auto;}
.tr-wrap2 div {min-width: 92%; margin-right: 10px; margin-bottom: 80px;}
.our-clnt-slider div img {width: 100%; height: 131px; margin-bottom: 0; padding-right: 0; object-fit: cover;}
.our-clnt-slider {margin-right: 0 !important; min-width: 100% !important; margin-left: 0 !important;}

.team-row-block .our-team-card,
.opc {
  border: 1px solid #dddddd; 
  padding: 20px; 
}

.carousel-caption {left: 15px !important; padding-top: 5.25rem;}
.csb-slider-block .card-style {padding: 5em;}
.my-instructor-block > .myi-image-block img {width: 80px; height: 80px;} 
.course-certificate-box ul li {width: 100%;}
.selpace-left-img-small {height: 200px;}
.btn-v {color: #000000; padding: 10px 30px;}
.slider-wps {padding-bottom: 10px;}
.carousel-item-cnt {align-items: start; height: 100%; padding-top: 134px;}
.checkout-form.mt-4 {margin-top: 0 !important;}
.checkout-form > div {margin-bottom: 20px;}
.checkout-form p.text-danger {margin-top: 5px !important;}
.mt--sm--5 {margin-top: 50px !important;}
.mt--sm--4 {margin-top: 40px !important;}
.page-center-div-block > div {width: 100%;}
.wrap-sm-btn-block {flex-wrap: wrap;}
.wrap-sm-btn-block .btn-v {width: 100%; margin: 0; margin-bottom: 10px;}
.carousel-indicators {left: 2% !important; bottom: 88px !important;}

}


.wrapper-slider-new {
  height: auto !important;
}

.wrapper-slider-new .slick-slide { 
  margin: 0 !important;
}

@media(max-width: 380px) {
  .second-img, 
  .third-img {
    width: 120px;
    height: 120px;
}

.first-img, 
.fourth-img {
  width: 80px;
  height: 80px;
}
}


@media(max-width: 360px) {
  .footer-a,
  .bottom-footer-text {
    font-size: 9px !important;
  }

  .second-img, 
  .third-img {
    width: 110px;
    height: 110px;
}

.first-img, 
.fourth-img {
  width: 75px;
  height: 75px;
}
}

@media(max-width: 350px) {
  .second-img, 
  .third-img {
    width: 100px;
    height: 100px;
}

.first-img, 
.fourth-img {
  width: 70px;
  height: 70px;
}
}

@media(max-width: 330px) {
  .second-img, 
  .third-img {
    width: 90px;
    height: 90px;
}

.first-img, 
.fourth-img {
  width: 65px;
  height: 65px;
}
}

.cursor-pointer{
  cursor: pointer;
}

.csb-slider-block .slick-slide>div,
.csb-slider-block .col-md-3.course-small-box,
.csb-slider-block .card-style,
.my-instructor-block {
height: 100%;
}

.csb-slider-block .slick-track{
    display: flex !important;
}

.csb-slider-block .slick-slide{
    height: inherit !important;
}

@media only screen and (min-device-width: 320px) 
                   and (max-device-width: 767px) 
                   and (orientation: landscape) {
                    .four-img-box-course1 { width: 68% !important;}
                    .frame-img-cnt-block {width: 32% !important;}
                    .slefpace-heading {font-size: 15px;}
                    .frame-img-cnt-block ul {padding-left: 15px;}
                    .crtfct-cnt-wrapp {width: 68% !important;}
                    .crtfct-cnt-wrapp + .col-md-5.ms-auto {width: 32% !important;}
                    .slefpace-heading-small {font-size: 14px;}
                    .my-instructor-block > .myi-image-block img {margin: 0 auto; margin-top: 10px;}
}

@media(min-width: 767px) and (max-width: 991px) {

  .slder-text-size,
  .carousel-caption h5,
  .section2-heading-our-clnt h1,
  .trnd-heading-last h2 {
    font-size: 32px !important;
  }

  h2.title,
  h2.message-heading {
    font-size: 32px !important;
  }

  .carousel-caption p,
  .test-size-slider-down-pera,
  .section-content,
  .wps-pera {
    font-size: 14px;
  }

  .test-size-slider-down,
  .section2-heading h1 {
    font-size: 26px;
  }

  .first-card-style,
  .wps-name {
    font-size: 18px;
  }

  .wps-postion {
    font-size: 16px;
  }

  .crcl-img-block {width: 170px; height: 170px;}
  .crcl-img-block::after {right: -8px;}

}

@media(min-width: 576px) {
  .home-carousel-image-block.sm {display: none;}
}

@media(max-width: 576px) {
  .home-carousel-image-block.lg {display: none;}
}


.socialClick{
  cursor: pointer;
}

/* slider css  */
.color-for-background .carousel-item {

  position: relative;

}




.color-for-background .carousel-inner > .carousel-item::after {

  content: '';

  position: absolute;

  width: 100%;

  height: 100%;

  top: 0;

  left: 0;

  z-index: 1;

  display: block !important;

}




.color-for-background .carousel-inner > .carousel-item:nth-child(1):after {

  background-color: rgba(211, 244, 51, 0.7);

}




.color-for-background .carousel-inner > .carousel-item:nth-child(2):after {

  background-color: rgba(71, 93, 213, 0.7);

}




.color-for-background .carousel-inner > .carousel-item:nth-child(3):after {

  background-color: rgba(128, 128, 128, 0.7);

}

/* tab-nav-css    */
.tab-nav-text-transform{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  img {
    cursor: none !important;
  }
  .onlyedge{
    cursor: none !important;
  }
}

img:not(.person) { pointer-events: none !important;}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.color-for-background .carousel-item,
.color-for-background .carousel-inner,
.color-for-background .carousel 
{
  height: 100%;
}
.left-slide .carousel-item{
  height:100vh !important;
}
.text-ellipsis{
  white-space: nowrap; 
  width: 250px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.tooltip-text{
  max-width: 300px !important; /* Remove any maximum width */
 text-align: justify;
  word-wrap: break-all !important;
  color: black !important;
  background-color: #d3f433 !important;
  padding: 10px !important;
  border-radius: 6px;
}
.display-text{
  white-space: nowrap; 
  width: 50px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
/* CustomStyles.css */
@media (max-width: 1624px) {
  .height-100vh-ipad {
    height: 100vh;
  }
}
@media (max-width:576px) {
  .login-slider {
    display: none;
  }
}
@media (min-width:576px) {
.login-slider,
.login-signup-wrapp{
  width: 50% !important;
}
}


.dec {text-decoration:none !important;}

.green_colod{  background-color: #d3f433;}