/* #root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
} */

.control-dots{
  width:242px;
  margin-bottom:220px;
  left:13rem
}

.my-toast-message-reset{
display: flex !important;
justify-content: space-around !important;
}
.Toastify__close-button{
  margin: 10px !important;
}
.css-1pqm26d-MuiAvatar-img{
  object-fit: contain ;
  object-position: center;
}
.Toastify__close-button{
  padding: 0px !important;
  width: 45px !important;
}
.navbarimg-img{
  HEIGHT: 40PX;
    width: 40px;
    border-radius: 23px;
    object-fit: cover;
  object-position: center !important;
}